.nav-row {
  background-color: $background-accent-color;
}

.navbar {
  border-bottom: none;
}

.navbar-brand {
  background-color: $brand-blue;
  padding: 0px;

  .logo-container {
    display: flex;
    flex-wrap: nowrap;
    height: 100%;

    div {
      height: 100%;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        margin: 0;
      }
      &:first-child {
        flex-grow: 3;
      }
      &:nth-child(2) {
        background-color: #0078cc;
      }
    }
  }
}

.navbar-form {
  margin: 13px 0 0 0;
}
