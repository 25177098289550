$modal-height: 440px;
$modal-width: 420px;
$modal-height-one-field: 185px;
// Purposefully use camelcase so that developers will see
// it matches the same value in PinDestination.js
$modalRowMargin: 20px;

.ReactModalPortal {
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $transparent-black;
    z-index: map-get($zindex, overlay);
  }

  .embedded-mode-modal-overlay {
    position: fixed;
    top: $embedded-mode-header-height;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $transparent-black;
    z-index: map-get($zindex, overlay);
  }

  .panel {
    background-color: $white;
    box-shadow: none;
  }
}

.modal-prominent-text {
  font-family: 'Montserrat';
  font-weight: 600;
  color: $brand-orange;
}

@mixin form-control() {
  background-color: $light-gray;
  border-radius: $global-border-width;
  color: $gray;
  border: none;
  box-shadow: none;
}

@mixin modal {
  max-height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  border: none;
  background-color: $background-light-color;
  color: $blue-black;
  border-radius: 4px;
  outline: none;
  padding: 0px;
  opacity: 1;
  width: $modal-width;
  $modal-padding: 30px;
  &.one-field {
    height: $modal-height-one-field;
  }
  .panel {
    border-radius: 4px 4px 0 0;
  }
  .panel-heading {
    background-color: $brand-blue;
    border-radius: 4px 4px 0 0;
    color: $white;
    padding: 10px 20px 10px 20px;
  }
  .panel-body {
    padding: 20px $modal-padding $modal-padding $modal-padding;

    &.body-flat {
      padding: 0px;
    }
  }
  .modal-btn-group {
    button {
      background-color: $brand-blue;
      border: 2px solid $brand-blue;
      color: $white;
      width: 100px;
    }

    .btn-cancel {
      background-color: $background-light-color;
      color: $brand-blue;
    }
  }
  .modal-btn-group .btn:first-child {
    margin-right: $modalRowMargin;
  }
  .row:not(:last-child) {
    margin-bottom: $modalRowMargin;
  }
  .form-control.annotation {
    height: 100px;
  }
  .back-rnd-button {
    background: url('../../images/btn_round_back.png') -3px -3px no-repeat;
    width: 54px;
    height: 50px;
    cursor: pointer;
    float: left;
  }
  .add-rnd-button {
    background: $brand-blue url('../../images/plus.png') 50% 50% no-repeat;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    cursor: pointer;
    float: right;
  }
  .title {
    display: block;
    overflow: hidden;
    color: #ffffff;
    text-align: center;
  }
  h6 {
    &.title {
      font-size: 14px;
      font-weight: normal;
      text-transform: uppercase;
    }
  }
  .actions {
    .action {
      text-align: center;
      display: inline-block;

      &:not(:last-child) {
        margin-right: 40px;
      }
    }

    button {
      background-color: $brand-blue;
      border: 2px solid $brand-blue;
      color: $white;
      width: 80px;

      &:not(:last-child) {
        margin-right: 10px;
      }

      &.auto-width {
        width: auto;
        padding-left: 20px;
        padding-right: 20px;
      }
    }

    .btn-cancel {
      background-color: $background-light-color;
      color: $brand-blue;
    }

    .icon-edit,
    .icon-delete {
      margin: 0 auto;
      margin-bottom: 3px;
      cursor: pointer;
    }
  }
  a,
  a:hover,
  a:active,
  a:visited {
    color: $brand-blue;
    cursor: pointer;
  }
  .info-block {
    .reason {
      color: $brand-red;
    }

    .general {
      color: black;
    }

    .description {
      margin-left: 5px;
      color: $gray;
    }
  }
}

@mixin modal-fullscreen {
  @include modal;

  transform: none;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  bottom: 0;
  padding: 35px;
  background-color: $background-color;
  overflow: hidden;
}

@mixin modal-large {
  @include modal;
  height: 80%;
  width: 90%;
}

.modal-info-content-fullscreen {
  @include modal-fullscreen;

  .modal-btn-group .btn:first-child {
    margin-right: 0px;
  }

  ul {
    max-height: 140px;
    overflow-y: scroll;
  }
}

.modal-pin-destination {
  @include modal-fullscreen;

  padding: 0px;
  color: $white;

  .container:first-child {
    height: 100%;
    width: 100%;
    margin: 0px;
    padding: 0px;
  }

  .left-column {
    @extend .col-xs-4;
    background-color: #212738;
    height: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }

  .left-column > .row:first-child {
    padding-top: 35px;
  }

  .left-column > .row {
    margin-left: 35px;
    margin-right: 35px;
  }

  .right-column {
    @extend .col-xs-8;
    height: 100%;
    padding: 0px;
  }

  .view-tabs {
    width: 50%;
    margin: 0 auto;

    .btn {
      color: $white;
    }

    .btn.active {
      color: $black;
    }
  }
}

.modal-repair-history {
  @include modal-fullscreen;

  .add-btn-container {
    padding-right: 25px;
    position: absolute;
    right: 0;

    .add-button {
      background: $brand-blue url('../../images/plus.png') 50% 50% no-repeat;
      width: 50px;
      height: 50px;
      border-radius: 25px;
      -webkit-border-radius: 25px;
      -moz-border-radius: 25px;
      cursor: pointer;
      float: right;
    }

    .add-button.active {
      background: $brand-blue url('../../images/cancel.png') 50% 50% no-repeat;
      margin-left: 8px;
    }

    .btn {
      background: $white;
      color: $brand-blue;
      float: right;
      margin: 8px 7px -7px 0px;
    }
  }
}

.modal-state-content {
  @include modal;
  transform: translate(-50%, -50%) !important;

  &.wider {
    width: $modal-width * 1.2;
  }

  .panel .panel-heading {
    height: 60px;

    .panel-title-component {
      font-size: 10px;
      letter-spacing: 0.2px;
      opacity: 0.7;
    }

    .panel-title {
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0.4px;
    }

    .row {
      height: 100%;
    }

    .close-button-container {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 100%;
    }
  }

  textarea {
    @include form-control();
  }

  .Select {
    border-radius: $global-border-width;

    .Select-control {
      border: none;
    }

    .Select-placeholder {
      @include form-control();
    }

    .Select-value {
      @include form-control();

      .Select-value-label {
        color: $gray;
      }
    }
  }

  label {
    font-size: 13px;
    font-weight: normal;
    color: $gray;
  }
}

.modal-confirmation {
  @include modal;
  transform: translate(-50%, -50%) !important;

  &.wider {
    width: $modal-width * 1.2;
  }

  .panel {
    .panel-heading {
      height: 40px;

      .panel-title-component {
        font-size: 10px;
        letter-spacing: 0.2px;
        opacity: 0.7;
      }

      .panel-title {
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0.4px;
      }

      .row {
        height: 100%;
      }

      .close-button-container {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 100%;
      }
    }

    .message {
      font-size: 1.2em;
    }
  }

  label {
    font-size: 13px;
    font-weight: normal;
    color: $gray;
  }
}

.modal-connector-view {
  @include modal-fullscreen();
}

.embedded-mode-modal-connector-view {
  @include modal-fullscreen();
  padding: 0;
}

.modal-repair-history-view {
  @include modal-fullscreen();
  padding: 0;
}

.modal-state-content-fullscreen {
  @include modal-fullscreen();
}

.modal-switch-test {
  @include modal-large;
  transform: translate(-50%, -50%) !important;

  .panel-heading {
    background-color: $brand-blue;
    border-radius: 4px 4px 0 0;
    color: $white;
    height: 60px;
    padding: 20px 20px 20px 20px;
  }

  .panel-body {
    padding: 0 0 0 0;
  }

  .ReactTable {
    border: none;

    .rt-table {
      border-radius: 0px;
    }

    .rt-thead {
      background-color: $light-gray;
    }

    .current-vehicle-test {
      color: $brand-green;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 1px;
      margin-bottom: 0px;
    }
  }
}

.modal-same-issues {
  @include modal-fullscreen;

  .panel-heading {
    background-color: $brand-blue;
    border-radius: 4px 4px 0 0;
    color: $white;
    height: 60px;
    padding: 20px 20px 20px 20px;
  }

  .panel-body {
    padding: 0 0 0 0;
  }
}
