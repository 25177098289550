.panel {
  border: none;
  margin-bottom: 0;

  // Panels should fill their container
  height: 100%;

  .panel-heading {
    font-size: 12px;
    height: 30px;
    padding: 6px 6px;
  }

  > .list-group,
  > .panel-collapse > .list-group {
    .list-group-item {
      border-width: $global-border-width 0;
    }
    // Add border bottom to last one
    &:last-child {
      .list-group-item:last-child {
        border-bottom: $global-border-width solid $transparent-black;
      }
    }
  }
}

.panel .panel-collapse {
  -ms-overflow-style: none;
}

// Styling of nested panel headers
.panel .panel .panel-heading {
  background-color: $dark-blue-black;
  color: $white;
  opacity: 0.7;
  font-size: 10px;
}
