.embedded-mode-switch-test-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.embedded-mode-switch-test-footer {
  display: flex;
  align-items: center;
  justify-content: center;
}

// NOTE: the same value used in EmbeddedMode/SwitchTestView.js for smooth scrolling,
// if you change this value you should make corresponding change in JS code.
$switch-test-table-row-height: 70px;

.embedded-mode-switch-test-table {
  font-size: 18px;
  line-height: 30px;
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;

  thead {
    background-color: #5a6382;
  }

  thead th {
    height: $switch-test-table-row-height;
    border-bottom: 1px $transparent-light-black solid;
  }

  thead th:nth-child(1) {
    padding-left: 20px;
  }

  tbody td:nth-child(1) {
    padding-left: 20px;
  }

  // column with action
  thead th:nth-child(5) {
    width: 80px;
  }

  thead th:nth-child(6) {
    width: 100px;
  }

  tbody td:nth-child(6) {
    text-align: center;
  }

  tbody td {
    height: $switch-test-table-row-height;
    text-align: left;
    border-bottom: 1px $transparent-light-black solid;
  }
}

.embedded-mode-current-vehicle-test {
  color: $brand-green;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1px;
  margin-bottom: 0;
}

.embedded-mode-switch-test-icon {
  transform: scale(0.8);
}
