.embedded-mode-table-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.embedded-mode-table-scroll-header {
  height: 80px;
  border-bottom: 1px solid $dark-gray;
}

.embedded-mode-table-scroll-content {
  flex-grow: 1;
  height: 100%;
  overflow: hidden;
}

.embedded-mode-table-scroll-footer {
  height: 80px;
  border-top: 1px solid $dark-gray;
}
