.tracer-button {
  z-index: 7000;
  cursor: pointer;
  background: transparent;
  font-size: 14px;
  border: 2px solid #5a6382;
  border-radius: 20px;
  padding-left: 20px;
  padding-right: 20px;
  height: 40px !important;

  &:hover {
    background-color: #5a6382;
  }

  &:active {
    border-color: #454c65;
    background-color: #454c65;
  }

  &:disabled {
    border-color: #3d455d;
    color: #3d455d;

    &:hover {
      background-color: inherit;
    }

    &:active {
      border-color: inherit;
      background-color: inherit;
    }
  }

  &.small {
    font-size: 12px;
    border-radius: 12px;
    padding-left: 12px;
    padding-right: 12px;
    height: 24px !important;
  }
}
