.btn-large {
  background-color: $blue-black;
  border-radius: 10px;
  border: 0;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
  height: 100%;
  width: 100%;
}

.btn-large svg {
  margin-top: 25%;
  height: 30%;
  width: 30%;
}

.btn-large use {
  fill: $brand-purple;
}

.btn-large p {
  font-size: 22px;
  hyphens: auto;
  margin: 10% 10% 30% 10%;
  overflow-wrap: break-word;
  white-space: normal;
  word-wrap: break-word;
  max-height: 1em;
}

div.btn-large:hover,
div.btn-large:active {
  background-color: $brand-blue;
}

div.btn-large:hover use,
div.btn-large:active use {
  fill: $white;
}

.btn-warn {
  border-color: red;
  color: red;
  background-color: white;

  &:hover {
    color: red;
  }
  &:focus {
    color: red;
  }
}
