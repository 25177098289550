$notification-height: 60px;
.notification {
  background-color: $dark-blue;
  position: fixed;
  bottom: 0;
  width: 100vw;
  height: $notification-height;
  font-size: 18px;
  color: $white;
  z-index: map-get($zindex, notification);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
}

@keyframes slideUp {
  0% {
    transform: translateY($notification-height);
  }
  100% {
    transform: translateY(0px);
  }
}

.slideIn {
  transform: translateY($notification-height);
  animation: slideUp 0.75s 0s 1 ease forwards;
}

.notification-base {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  &.notification-error {
    background-color: $brand-red;
  }
  &.notification-network-error {
    background-color: $report-yellow;
    .btn {
      margin-left: 10px;
      background-color: $background-light-color;
      color: $dark-blue-black;
      border: $global-border-width solid $background-light-color;
    }
  }
}

.icon-close {
  position: absolute;
  right: 20px;
}
