@import '../assets/styles/partials/variables';

.block-diagram,
.detail-diagram {
  width: 100%;
  height: 100%;
}

.base-diagram-container,
.tracer-image-viewer {
  position: relative;
}

.base-diagram-controls {
  z-index: 1;
  bottom: 40px;
  left: 10px;
  position: absolute;
  margin: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  .base-diagram-controls-button-in,
  .base-diagram-controls-button-out,
  .base-diagram-controls-button-reset {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1pt solid hsl(216, 23%, 36%);
    padding: 8px;
    svg {
      height: 16px;
      width: 16px;
      path {
        fill: hsl(216, 23%, 36%);
      }
    }

    svg:hover {
      path {
        fill: hsl(236, 51%, 77%);
      }
      cursor: pointer;
    }
  }
  .base-diagram-controls-button-in {
    border-radius: 24px 0px 0px 24px;
    border-right: 0px;
  }
  .base-diagram-controls-button-out {
    border-radius: 0px 24px 24px 0px;
    border-left: 0px;
  }
}
