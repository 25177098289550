.embedded-mode-body {
  display: flex;
  flex-direction: column;
  margin: 0;
  height: 100vh;
  touch-action: none;
}

.embedded-mode-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #0189e9;
  padding: 6px 16px;
  font-size: 20px;
  font-weight: 700;
  height: $embedded-mode-header-height;
}

.embedded-mode-header-item {
  display: flex;
  align-items: center;
  height: $embedded-mode-header-height;
}

.embedded-mode-container {
  height: calc(100vh - #{$embedded-mode-header-height});
  display: flex;
  flex: 1;
}

.embedded-mode-main {
  flex-grow: 1;
  height: 100%;
  width: calc(
    100vh - #{$embedded-mode-panel-width + $embedded-mode-action-bar-width}
  );
}

.embedded-mode-panel {
  width: $embedded-mode-panel-width;
  height: 100%;
  background-color: #212738;
  overflow: hidden;
}

.embedded-mode-action-bar {
  width: $embedded-mode-action-bar-width;
}

.embedded-mode-harness-view {
  height: 100%;
  overflow: hidden;
  position: relative;
}

.embedded-mode-vehicle-view {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.embedded-mode-component-details-main {
  height: 100%;
  width: 100%;
}

.embedded-mode-component-details-panel {
  flex-shrink: 0;
  width: $embedded-mode-component-details-panel-width;
  height: 100%;
  background-color: white;
  color: black;
  padding: 16px 25px;
}
