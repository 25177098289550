.detail-label {
  pointer-events: none;
  font-size: 12px;
  text-rendering: geometricPrecision;
  user-select: none;
  stroke: #212738;
  stroke-width: 0.5em;
  fill: #d6e7ff;
  paint-order: stroke;
  stroke-linejoin: round;
}
