.gradient-container {
  position: relative;
}

.gradient-base {
  height: 100px;
  width: 100%;
  position: absolute;
  pointer-events: none; // Allow click events through gradient
  z-index: 1;
}

.gradient-top {
  @extend .gradient-base;
  background-image: linear-gradient(to top, rgba(39, 47, 66, 0), $blue-black);
}

.gradient-bottom {
  @extend .gradient-base;
  bottom: 0;
  background-image: linear-gradient(
    to bottom,
    rgba(46, 56, 79, 0) 5%,
    $light-blue-black 98%
  );
}
