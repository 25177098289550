.pan-control {
  > div {
    background-color: $embedded-mode-control-background-color;
    width: $embedded-mode-control-width;
    height: $embedded-mode-control-height;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  > div:active {
    background-color: $embedded-mode-control-active;
  }

  .left {
    @include disable-control;
    z-index: map-get($zindex, button);
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-$embedded-mode-control-height / 2);
  }

  .right {
    @include disable-control;
    z-index: map-get($zindex, button);
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-$embedded-mode-control-height / 2);
  }

  .up {
    @include disable-control;
    z-index: map-get($zindex, button);
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-$embedded-mode-control-width / 2);
  }

  .down {
    @include disable-control;
    z-index: map-get($zindex, button);
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-$embedded-mode-control-width / 2);
  }
}
