.col-xs-12 {
  margin-bottom: 1em;
}

.col-centered {
  float: none;
  margin: 0 auto;
}

.w-100 {
  margin: 0 auto;
  width: 100%;
}

.center {
  display: flex;
  justify-content: center;
}
