.report-failures {
  $pageMargin: 30px;
  $headerBorderRadius: 3px;

  // Override overflow:hidden that is on the body so the whole view scrolls
  position: absolute;
  overflow: auto;
  width: 100%;
  height: 100%;

  .panel {
    margin-top: $pageMargin;
    border-radius: $headerBorderRadius;
  }
  .panel .panel-body {
    padding: 0;
  }
  .panel .panel-heading {
    background-color: $dark-blue-black;
    cursor: pointer;
    color: $white;
    font-size: 16px;
    padding: 10px 20px 10px 20px;
    height: 60px;
    display: flex;
    align-items: center;
    border-radius: $headerBorderRadius;

    :last-child {
      margin-left: auto;
    }
  }

  .row {
    padding-left: $pageMargin;
    padding-right: $pageMargin;
  }
}

.daterangepicker {
  color: black !important;
}

.report-title {
  background-color: $brand-blue;
  height: 60px;

  div {
    display: flex;
    align-items: center;
    span {
      font-size: 18px;
      font-weight: 300;
      letter-spacing: 0.5px;
    }
  }
}

.report-nav {
  background-color: $dark-blue-black;
  border-bottom: 1px solid $transparent-gray;
  padding-bottom: 10px;
  padding-top: 10px;
}

.report-overview {
  background-color: $dark-blue-black;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
}

.circle {
  $diameter: 12px;
  border-radius: 50%;
  width: $diameter;
  height: $diameter;
  display: inline-block;
}

.circle-blue {
  @extend .circle;
  background: $brand-blue;
}
.circle-yellow {
  @extend .circle;
  background: $report-yellow;
}
.circle-red {
  @extend .circle;
  background: $report-red;
}

.badge-report {
  display: flex;
  align-items: center;

  background-color: $transparent-white;
  border-radius: 100px;
  height: 30px;

  font-size: 14px;
  font-weight: 300;

  span {
    padding: 6px 10px 6px 10px;
  }

  &.active {
    background-color: $white;
    color: $dark-blue-black;
  }
}

.report-table-row {
  background-color: $blue-black-report;
  font-size: 13px;
  font-weight: 300;
}

.report-table-header {
  background-color: $transparent-white;
  thead tr th {
    font-size: 13px;
    font-weight: 300;
    border-bottom: none;
  }
}

.report-table-body {
  // Row divider style
  tbody tr td {
    border-top: 1px solid $transparent-white;
  }
}

.chart-label {
  color: $transparent-light-gray;
}
