$global-border-width: 2px;
$header-bg: #80c4f4;

h2 {
  font-size: 22px;
  font-weight: 400;
}

label {
  font-weight: normal;
}

.mb-1 {
  margin-bottom: 20px;
}

.mb-2 {
  margin-bottom: 20px;
}

.mb-3 {
  margin-bottom: 30px;
}

.mt-1 {
  margin-top: 20px;
}

.mt-2 {
  margin-top: 20px;
}

.ml-1 {
  margin-left: 10px;
}

.ml-2 {
  margin-left: 20px;
}

.mr-1 {
  margin-right: 10px;
}

.mr-2 {
  margin-right: 20px;
}

.gray {
  color: gray;
}

.white {
  color: white;
}

.gray-text-color {
  color: #212738;
}

.w-100 {
  width: 100%;
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.margin-auto {
  margin: auto;
}

.justify-content-center {
  justify-content: center;
}

.space-between {
  justify-content: space-between;
}

.align-items-center {
  align-items: center;
}

.blue-black {
  color: $blue-black;
}
