#users-list {
  max-width: 1100px;
  margin: auto auto 20px;

  .edit-user-link {
    color: $brand-blue;
  }

  .loading {
    margin: 35px 0 10px 10px;
  }

  .users-list-header {
    display: flex;
    justify-content: center;
    align-items: end;
    margin: 20px;

    // Equal space between all header controls.
    > * {
      margin-right: 20px;
    }
  }

  // User name filter
  .search-box {
    color: $black;
    width: 400px;

    input {
      width: 100%;
      padding: 0.5em;
      resize: none;
      background-color: $white;
      border-radius: 0.4rem;
      box-sizing: border-box;
      line-height: 1.5;
    }
  }

  .load-more-users {
    display: flex;
    justify-content: center;
    align-items: end;
    margin: 30px;
  }
}

#user-add-form,
#user-edit-form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  form {
    flex: 1;
    width: 400px;
  }

  .row,
  .Select,
  .btn {
    margin-top: 10px;
  }

  .btn-wrapper > .btn {
    margin: 10px;
  }

  .select {
    color: $blue-black;
  }
}
