@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src:
    local('Montserrat Light'),
    local('Montserrat-Light'),
    url('../../fonts/Montserrat/Montserrat-Light.ttf') format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC,
    U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src:
    local('Montserrat Regular'),
    local('Montserrat-Regular'),
    url('../../fonts/Montserrat/Montserrat-Regular.ttf') format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC,
    U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src:
    local('Montserrat Medium'),
    local('Montserrat-Medium'),
    url('../../fonts/Montserrat/Montserrat-Medium.ttf') format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC,
    U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src:
    local('Montserrat SemiBold'),
    local('Montserrat-SemiBold'),
    url('../../fonts/Montserrat/Montserrat-SemiBold.ttf') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC,
    U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src:
    local('Montserrat Bold'),
    local('Montserrat-Bold'),
    url('../../fonts/Montserrat/Montserrat-Bold.ttf') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC,
    U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  src:
    local('Montserrat ExtraBold'),
    local('Montserrat-ExtraBold'),
    url('../../fonts/Montserrat/Montserrat-ExtraBold.ttf') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC,
    U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

@font-face {
  font-family: 'TracerIcons';
  src: url('../../fonts/TracerIcons/TracerIcons-Regular.ttf') format('truetype');
}
