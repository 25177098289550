.embedded-select {
  display: flex;
  flex-direction: column;
  height: 320px;
  background-color: #212738;
  color: white;
  .embedded-control {
    border-width: 1px;
    flex: 0 0 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 1px;
    background-color: $embedded-mode-control-background-color;

    &.disabled-control {
      path {
        fill: $embedded-mode-disabled;
      }
    }
  }
  .embedded-control:active {
    background-color: $embedded-mode-control-active;
  }
}

.embedded-select-list {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
}

.embedded-select-item {
  display: flex;
  flex-direction: column;
  flex: 0 0 80px;
  height: $embedded-mode-control-height;
  align-items: center;
  border-width: 1px;
  border-style: solid solid none;
  border-color: $embedded-mode-page-background-color;
  align-items: center;
  justify-content: center;
  font-size: 16px;

  &.selected {
    background-color: #0089e9;
  }

  .embedded-select-item-suggested {
    opacity: 0.5;
  }
}

.embedded-select-item:active {
  background-color: $embedded-mode-control-active;
}
.embedded-select-item:last-child {
  border-style: solid;
}
