.btn-collapse {
  // Style so it is the same size as a panel heading
  height: 30px;
  padding: 6px;
  border-radius: 100px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  background-color: $white;
  border-color: $white;
  color: $brand-blue;
  border: none;
  border-bottom: 1px solid transparent;

  // Position and make sure button can be clicked over graph
  position: absolute;
  right: 0px;
  z-index: map-get($zindex, button);
}

.side-panel-collapse {
  z-index: map-get($zindex, header);
}

.sidebar {
  z-index: map-get($zindex, header) !important;
  min-width: 220px;

  // Make sure the dtc sidebar only scrolls list contents and not the whole sidebar content.
  height: 100%;
}

.sidebar-content {
  // Don't overflow the main container. This is obvious in IE.
  overflow-y: hidden;
  -ms-overflow-y: hidden !important;
}

#navigation-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 60px;
  z-index: map-get($zindex, overlay);

  .btn {
    border: 2px solid $brand-blue;
    background: $brand-blue;
    width: 100%;
    margin: 10px;
  }
}
