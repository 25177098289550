.mapping-table-wrapper {
  color: black;
  max-height: 376px;
  overflow: scroll;
  margin-bottom: 10px;
  background-color: white;
  border-radius: 5px;

  .mapping-table-header {
    display: flex;
    align-items: center;
    height: 50px;
    padding-right: 10px;
    padding-left: 10px;
  }
  .mapping-table-header button {
    flex-basis: 12%;
    margin-left: auto;
  }

  .table {
    margin-bottom: 0;
    tr:hover {
      cursor: pointer;
    }
  }

  svg {
    vertical-align: middle;
  }
}

.mapping-vehicle-view-wrapper {
  height: 70vh;
}

.start-component-header {
  display: flex;
  align-items: center;
  button {
    flex-basis: 12%;
    margin-left: auto;
    margin-right: 10px;
  }
}
