@media (min-width: $screen-sm-min) {
  .navbar-nav.navbar-center {
    position: absolute;
    left: 50%;
    transform: translatex(-50%);
  }
}

.navbar {
  .btn {
    height: 40px;
    font-size: 12px;
  }
}

.navbar-brand > img {
  height: 100%;
  padding: 11px;
  width: auto;
}

.back-button {
  display: flex;
  color: $white;
  font-size: 12px;
  font-style: normal;
  font-stretch: normal;
  font-weight: 600;
  line-height: 1.43;
  letter-spacing: normal;
  align-items: center;
  height: 48px;
  padding-left: 13px;
  background-color: $background-accent-color;
  cursor: pointer;

  .toggle-icon {
    display: inline-block;
    height: 20px;
  }
}
