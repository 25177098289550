.vin-panel-container {
  @extend .row;
  background-color: $blue-black;
  margin: 0;

  &.cantfix {
    background-color: #cc422e;

    span:nth-child(2) {
      color: $brand-yellow !important;
    }
  }
}

.vin-panel {
  display: flex;
  color: $white;
  font-size: 12px;
  font-style: normal;
  font-stretch: normal;
  font-weight: 600;
  line-height: 1.43;
  letter-spacing: normal;

  .current-test-info {
    color: $white;
  }

  .vin-item {
    padding: 13px 0 2px;
  }

  .vin-panel-left {
    float: left;
    padding-left: 10px;
    width: 90%;
  }

  .vin-panel-right {
    display: flex;
    flex-direction: column;
    float: right;
    padding: 13px 10px 10px 0;
    width: 10%;
  }
}

/* Get smaller when Bootstrap changes panel width */
@media all and (min-width: $screen-sm-min) and (max-width: 890px),
  all and (min-width: $screen-lg-min) and (max-width: $screen-lg-max) {
  .vin-panel {
    .vin {
      font-size: $small-font-size;
    }
  }
}
