#demo-form {
  color: black;
  .or-separator {
    text-align: center;
    width: 100%;
    margin: 5px 0;
  }
  #payload {
    margin-bottom: 10px;
  }
}

.react-codemirror2 {
  padding-top: 10px;
}

.form.form-payload {
  padding: 0px 200px;
}

.navbar-demo-btn {
  position: absolute;
  bottom: 5px;
  right: 50%;
  img {
    width: 35px;
    height: 35px;
  }
  z-index: map-get($zindex, button);
}

@media (max-height: 750px) {
  .CodeMirror {
    height: 150px;
  }
}
