#pin-details {
  color: $white;
}

.pin-details-tooltip {
  color: $blue-black !important;
  background-color: $background-light-color !important;
  opacity: 1 !important;
  border: 2px solid $background-accent-color !important;
  width: 300px !important;
  &:hover {
    visibility: visible !important;
  }
}

#pin-details hr {
  border-color: $brand-purple;
}

#pin-details .pin-color {
  p {
    float: left;
    padding-right: 10px;
  }

  svg {
    top: -4px;
    position: relative;
  }
}

#pin-details .component-connector-image {
  padding-top: 10px;
}

.dl {
  margin-left: 0px;
  margin-right: 0px;
  font-size: 12px;
}

.dt {
  margin: 0 0 4px 0;
  float: left;
  clear: left;
  width: 45%;
}

.dd {
  float: left;
  margin: 0 0 4px 1%;
  width: 54%;
}

.connector-container {
  height: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .connector-preview {
    height: 400px;
  }

  .btn-connector-fullview-container {
    margin-top: 15px;
  }
}
