.controls {
  z-index: map-get($zindex, button);
  display: flex;
  background-color: $embedded-mode-control-background-color;
  justify-content: center;
  align-items: center;
  height: $embedded-mode-control-height;
  width: $embedded-mode-control-width;
  cursor: pointer;

  &.disabled {
    path {
      fill: $embedded-mode-disabled;
    }
  }
}

.controls-stub {
  height: $embedded-mode-control-height;
  width: $embedded-mode-control-width;
}

.controls:active {
  background-color: $embedded-mode-control-active;
}

.embedded-mode-wide-control {
  z-index: map-get($zindex, button);
  display: flex;
  background-color: $embedded-mode-control-background-color;
  justify-content: center;
  align-items: center;
  height: $embedded-mode-wide-control-height;
  width: $embedded-mode-wide-control-width;
  cursor: pointer;

  path {
    fill: gray;
  }

  &.disabled {
    path {
      fill: $embedded-mode-disabled;
    }
  }
}

.embedded-mode-wide-control:active {
  background-color: $embedded-mode-control-active;
}

.embedded-mode-up-arrow {
  display: flex;

  svg {
    margin: auto;
  }
}

.embedded-mode-back-button {
  position: absolute;
  top: 0;
  left: 0;
}

.embedded-mode-clickable-animation:active {
  background-color: $embedded-mode-control-active;
}
