.left-right {
  display: flex;
}

.left-col {
  margin-right: auto;
  display: inline-flex;
  align-items: center;
}

.wizard-nav-breadcrumb {
  width: 100%;
  height: 60px;
  margin: 10px 0px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  font-size: 2rem;
}

.view-btn-group {
  padding: 10px 20px;
}

.back-rnd-button {
  background-image: url('../../images/btn_round_back.png');
  background-size: 50px 50px;
  width: 50px;
  height: 50px;
  cursor: pointer;
  float: left;
}

.memo-mapping-header {
  padding: 10px;
}
