.harness-view-vehicle-view-switch {
  position: absolute;
  bottom: 0;
  left: 0;

  &.disabled-control {
    path {
      fill: $embedded-mode-disabled;
    }
  }
}

.harness-view-vehicle-view-switch:active {
  background-color: $embedded-mode-control-active;
}
