$embedded-mode-wide-control-width: 100%;
$embedded-mode-wide-control-height: 40px;
$embedded-mode-control-width: 100px;
$embedded-mode-control-height: 70px;
$embedded-mode-control-active: rgba(90, 99, 130, 0.15);
$embedded-mode-control-background-color: rgba(90, 99, 130, 0.3);
$embedded-mode-page-background-color: #2f384f;
$embedded-mode-disabled: #5a6382;
$embedded-mode-header-height: 35px;
$embedded-mode-action-bar-width: 100px;
$embedded-mode-panel-width: 210px;
$embedded-mode-component-details-panel-width: 300px;
