#vehicle-tests-search {
  height: 100%;
  left: 0px;
  margin: auto;
  position: absolute;
  right: 0;

  .action-button {
    margin-right: 3px;
  }
}

#vehicle-tests-search h2 {
  font-size: 22px;
  font-weight: 400;
}

#vehicle-tests-search .form .form-group {
  font-size: 12px;
  font-weight: normal;
  padding-left: 0;
}

#vehicle-tests-search label {
  font-weight: normal;
}

.form-btn-inline,
.form-btn-inline:hover,
.form-btn-inline:focus {
  background-color: $brand-blue;
  border-color: $brand-blue;
  color: $white;
  font-size: 12px;
  margin-top: 22px;
  user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
}

.form-btn-inline:active {
  background-color: $white;
  border-color: $brand-blue;
  color: $brand-blue;
  font-size: 12px;
  margin-top: 22px;
  user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
}

.form-btn-inline {
  padding: 7px 12px;
}

#vehicle-test-help-message {
  font-size: 18px;
  line-height: 1.5;
  margin-top: 10px;
  text-align: center;
}
