.main-dashboard-container {
  display: flex;
  min-height: calc(100vh - 60px); // 60px is a height of top panel
}

$main-dashboard-button-max-width: 300px;
$main-dashboard-button-margin: 10px;
$main-dashboard-six-items-per-page-width: (
  3 * $main-dashboard-button-max-width + 6 * $main-dashboard-button-margin
);

.main-dashboard-button {
  max-width: $main-dashboard-button-max-width;
  margin: $main-dashboard-button-margin;
  height: 280px;
  background-color: $blue-black;
  border-radius: 10px;
  border: 0;
  font-size: 22px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: $brand-blue;

    use {
      fill: white;
    }
  }

  use {
    fill: $brand-purple;
  }

  // 720px:
  //    .main-dashboard-button height (280px) * 2 + margin (10px) * 4 == 600px
  //    .main-dashboard-pagination-wrapper height (50px)
  //    .main-dashboard-carousel-wrapper padding-top (10px)
  //    top navigation bar height (60px)
  @media screen and (max-height: 720px), (max-width: 1060px) {
    flex-direction: row;
    justify-content: left;
    height: 120px;
    font-size: 18px;
  }

  @media (max-height: 560px) {
    flex-direction: row;
    justify-content: left;
    height: 80px;
    font-size: 16px;
  }

  flex: 1 0 auto;
  box-sizing: border-box;
  width: 100%;
}

.main-dashboard-icon {
  flex: 0 0 100px;
  width: 100px;
  height: 100px;

  svg {
    width: 100%;
    height: 100%;
  }

  // same as @media in .main-dashboard-button (see there how 720px computes)
  @media screen and (max-height: 720px), (max-width: 1060px) {
    flex: 0 0 80px;
    width: 80px;
    height: 80px;
  }
}

.main-dashboard-pagination-button {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 0;
  padding: 0;
  margin: 6px;
  background-color: #5a6382;

  @media (max-height: 700px) {
    width: 8px;
    height: 8px;
  }

  &.active {
    background-color: white;
  }
}

.main-dashboard-carousel-wrapper {
  // One item: $main-dashboard-button-margin + $main-dashboard-button-max-width + $main-dashboard-button-margin
  width: 960px; // 3 items
  max-width: $main-dashboard-six-items-per-page-width;
  overflow: hidden;
  padding-top: 10px;
  margin: auto;

  // 740px == $main-dashboard-pagination-ctrl-width + 2 items (see above) + $main-dashboard-pagination-ctrl-width
  // 1060px == $main-dashboard-pagination-ctrl-width + 3 items (see above) + $main-dashboard-pagination-ctrl-width
  @media (min-width: 740px) and (max-width: 1060px) {
    width: 640px;
  }

  // Here width is the same as THREE_ITEMS_PER_PAGE_WIDTH in MainMenu.js
  // 740px == $main-dashboard-pagination-ctrl-width + 2 items (see above) + $main-dashboard-pagination-ctrl-width
  @media (max-width: 740px) {
    width: 320px;
  }
}

.main-dashboard-pagination-wrapper {
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}

$main-dashboard-pagination-ctrl-width: 50px;

.main-dashboard-pagination-ctrl {
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(90, 99, 130, 0.3);
  width: $main-dashboard-pagination-ctrl-width;
  cursor: pointer;

  &.prev-page {
    margin-left: -10px;
  }

  &.next-page {
    margin-right: -10px;
  }

  &.disabled {
    path {
      fill: #5a6382;
    }
  }
}
