.add-fault-wrapper {
  width: 100%;
  height: 100%;
}

.repair-history-modal-back-button {
  background: url('../../images/btn_round_back.png') -3px -3px no-repeat;
  width: 52px;
  height: 50px;
  cursor: pointer;
}

.repair-history-modal-header {
  height: 100px;
  padding: 20px;
  border-bottom: 1px solid gray;
  display: flex;
  align-items: center;
}

.add-fault-tabs-wrapper {
  flex-grow: 3;
}

.add-fault-tabs {
  width: 450px;
  margin: auto;
}

.repair-history-modal-title {
  color: white;
  margin-left: 30px;
  margin-top: 0;
  margin-bottom: 0;
}

.add-fault-content {
  height: calc(100% - 60px);
  width: 100%;
}

.add-fault-container {
  color: white;
  overflow-y: scroll;
}

.add-fault-table-wrapper {
  color: black;
  max-height: 286px;
  overflow: scroll;
  margin-bottom: 10px;
  background-color: white;
  border-radius: 5px;

  .add-fault-table-header {
    display: flex;
    align-items: center;
    height: 50px;
    padding-right: 10px;
    padding-left: 10px;
  }

  .add-fault-table-header button {
    flex-basis: 12%;
    margin-left: auto;
  }

  .table {
    margin-bottom: 0;

    tr:hover {
      cursor: pointer;
    }
  }

  svg {
    vertical-align: middle;
  }
}

.add-fault-image-view-wrapper {
  position: relative;
  width: 50%;
  height: 812px;
  border-right: 1px solid gray;
  margin-bottom: 50px;
}

.add-fault-search-and-submit-wrapper {
  width: 50%;
  padding-top: 20px;
  padding-left: 90px;
  padding-right: 50px;
}

.add-fault-details-wrapper {
  color: black;
  margin-bottom: 100px;
}

.repair-history-form-wrapper {
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
  max-width: 600px;
}

.image-viewer {
  height: inherit;

  .overflow-hidden {
    overflow: hidden;
    height: 100%;
  }
}

.repair-history-component-description {
  span.name {
    display: block;
  }

  span.description {
    color: $gray;
  }
}
