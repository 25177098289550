@import '../assets/styles/partials/variables';

#production-line-control {
  display: flex;
  flex-direction: row;
  justify-content: left;
  margin: 20px 0px 20px;

  > * {
    &:not(:last-child) {
      margin-right: 20px;
    }
  }

  .plant {
    flex: 20%;
  }

  .test-zone {
    flex: 40%;
  }

  .time-after-test {
    flex: 25%;
  }

  .apply-button {
    display: flex;
    flex-direction: column;
    flex: 15%;

    div {
      min-height: 29px;
    }

    button {
      width: 100%;
      align-self: flex-start;
      min-height: 38px;
    }
  }
}

.dashboard-results-help-message {
  text-align: center;
  color: white;
  font-size: 18px;
}

#production-line-control .control-name {
  color: white;
}

#production-line-page .page-name {
  color: white;
  text-align: center;
  font-size: 22px;
  margin: 30px 0px 30px;
}

#production-line-page {
  max-width: 968px;
  margin: 0 auto;
  color: $blue-black;

  #production-line-control {
    .control-select__menu-list {
      max-height: 1028px;
    }
  }
}
#production-line-page table {
  table-layout: fixed;
  width: 100%;
  overflow: hidden;
  border-radius: 4px;

  thead {
    vertical-align: middle;
    background-color: #80c4f4;
  }

  thead th:nth-child(1) {
    width: 20%;
  }

  thead th:nth-child(2) {
    width: 30%;
  }

  thead th:nth-child(3) {
    width: 20%;
  }

  thead th:nth-child(4) {
    width: 15%;
  }

  thead th:nth-child(5) {
    width: 15%;
  }

  th,
  td {
    padding: 10px 20px 10px 20px;
    border-bottom: 1px solid $light-gray;
  }

  tr td:nth-child(4) {
    button {
      width: 100%;
    }
  }

  tbody {
    background-color: white;
  }
}
