.component-list-item-title-wrap {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center; // Pull the last image to the right of the container
  margin-bottom: 6px;

  .icon-info {
    margin-left: auto;
  }
}

.component-list-item-title {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.4px;
  margin-right: 10px;
}

.component-list-item-description {
  font-size: 10px;
  opacity: 0.8;
  letter-spacing: 0.2px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

// TODO: consider improve this styling

.disabled,
.disabled:hover {
  background-color: inherit;
  color: inherit;

  .component-list-item-title,
  .component-list-item-description {
    opacity: 0.6;
  }
}
