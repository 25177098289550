#topology {
  height: inherit;
  .overflow-hidden {
    overflow: hidden;
    height: 100%;
    width: 100%;
    position: relative;
  }
}

.connector-view {
  div {
    width: 100%;
    height: 100%;
  }
}
