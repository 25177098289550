.list-group-item {
  margin-bottom: -$global-border-width;
  padding: 15px 10px;
  position: relative;

  &.active {
    background-color: $brand-dark-blue;
    padding: 15px 10px;

    &.verified {
      background-color: $brand-success;
    }

    &.faulty {
      background-color: $brand-danger;
    }
  }

  &.indicate {
    background-color: $brand-transparent-dark-blue;
  }

  &.misconfigured {
    background-color: $transparent-light-black;

    .icon-warning {
      cursor: pointer;
      margin-right: 10px;
    }
  }

  > .toggled-on {
    background: $brand-dark-blue;
    height: 100%;
    left: 0px;
    position: absolute;
    top: 0px;
    width: 8px;
  }

  > .toggled-on + div {
    margin-left: 10px;
  }

  &.related {
    padding-right: 16px;

    .bar {
      position: absolute;
      right: 0;
      top: 0;
      width: 8px;
      height: 100%;
      background-color: $brand-dark-blue;
    }

    &.active {
      .bar {
        background-color: #042c48;
      }
    }

    &.active.verified {
      .bar {
        background-color: #295829;
      }
    }

    &.active.faulty {
      .bar {
        background-color: #6d2418;
      }
    }
  }

  @media (max-width: 900px) {
    padding: 10px 5px;

    &.active {
      padding: 10px 5px;
    }
  }

  &.disabled,
  &.disabled:hover {
    background-color: inherit;
    color: inherit;
    .title,
    .subtitle {
      opacity: 0.6;
    }
  }
}

#component-list {
  .list-group-item {
    &.indicate {
      background-color: $brand-dark-blue;

      &.verified {
        background-color: $brand-success;
      }

      &.faulty {
        background-color: $brand-danger;
      }
    }
  }
}

#dtc-list {
  .list-group-item {
    &.active {
      background-color: $brand-transparent-dark-blue;
    }
    &.unsupported {
      background-image: url('../../../images/icons/svg/unsupported.svg');
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 100%;
    }
    .subtitle {
      white-space: normal;
    }
  }
}
