/* bootstrap-sass variable overrides */

// globals
$body-bg: $light-blue-black;
$text-color: $white;
$brand-success: $brand-green;
$brand-danger: $brand-red;

// button
$btn-border-radius-base: 100px;
$btn-border-radius-large: 100px;
$btn-border-radius-small: 30px;

$btn-default-border: $brand-blue;
$btn-default-bg: $brand-blue;
$btn-default-color: $white;

// fonts
$icon-font-path: '~bootstrap-sass/assets/fonts/bootstrap/'; // Required for bootsrap.scss to load
$font-family-sans-serif: 'Montserrat', sans-serif;

// grid
$grid-gutter-width: 20px;

// link
$link-color: $white;
$link-hover-color: $white;

// list-group
$list-group-border-radius: 0px;
$list-group-bg: $blue-black;
$list-group-border: $transparent-black;
$list-group-active-bg: $brand-dark-blue;
$list-group-active-border: $transparent-black;
$list-group-link-color: $white;
$list-group-link-hover-color: $white;
$list-group-hover-bg: $transparent-gray;

// navbar
$navbar-height: 60px;
$navbar-margin-bottom: 0px;
$navbar-border-radius: 0px;
$navbar-default-bg: $blue-black;
$navbar-default-border: transparent;
$navbar-default-brand-color: $brand-blue;

// padding - global
$padding-large-vertical: 6px;
$padding-small-vertical: 4px;

// panel
$panel-border-radius: 0px;
$panel-default-heading-bg: $white;
$panel-default-border: transparent;
$panel-default-text: $brand-blue;
$panel-bg: $blue-black;
$panel-heading-padding: 10px 10px;

// zindex
$zindex-modal-background: map-get($zindex, overlay);
$zindex-modal: map-get($zindex, modal);

@import '../../../../node_modules/bootstrap-sass/assets/stylesheets/_bootstrap.scss';

/* bootstrap stylesheet overrides (some things can't be fixed via sass variables but try those first!) */

@import 'custom/variables';
@import 'custom/btn';
@import 'custom/list-group';
@import 'custom/navbar';
@import 'custom/panel';
@import 'custom/table';
@import 'custom/multiselect';
@import 'custom/datepicker';
@import 'custom/grid';
