.embedded-panel-item {
  height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &.unsupported {
    background-image: url('../../../images/icons/embedded/svg/unsupported.svg');
    background-repeat: no-repeat;
    background-size: cover;
  }

  > div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  border-bottom: 1px solid #141721;
  padding: 0.2em 0.2em 0.2em 0.8em;

  .embedded-dtc-acronym {
    font-size: 14px;
    font-weight: 400;
  }
  .embedded-dtc-code {
    display: flex;
    font-size: 20px;
    font-weight: 700;

    svg {
      top: 0.125em;
      position: relative;
    }
  }
  .embedded-dtc-description {
    font-size: 14px;
    font-weight: 400;
  }
  &.selected {
    background-color: #0089e9;
  }

  .embedded-component-name {
    font-size: 20px;
    font-weight: 700;

    svg {
      top: 0.125em;
      position: relative;
    }
  }
  .embedded-component-description {
    font-size: 14px;
    font-weight: 400;
  }
}
