.embedded-mode-pin-details-wrapper {
  font-size: 15px;
  height: 100%;

  &.show-scroll {
    position: relative;
  }
}

.embedded-mode-pin-details {
  overflow-y: scroll;
  height: 100%;

  &.show-scroll {
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
    bottom: 50px;
    height: auto;
  }
}

.embedded-mode-label {
  color: #7f7f7f;
}

.embedded-mode-field {
  color: black;
  margin-bottom: 20px;
  font-weight: bold;
}

.embedded-mode-pin-color {
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
}

.embedded-mode-pin-details-scroll-up {
  position: absolute;
  top: 0;
  left: 0;
}

.embedded-mode-pin-details-scroll-down {
  position: absolute;
  bottom: 0;
  left: 0;
}
