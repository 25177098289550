.embedded-mode-pinout-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.embedded-mode-pinout-footer {
  display: flex;
  align-items: center;
  justify-content: center;
}

// NOTE: the same value used in EmbeddedMode/PinoutView.js for smooth scrolling,
// if you change this value you should make corresponding change in JS code.
$pinout-table-row-height: 70px;

.embedded-mode-pinout-table {
  line-height: 30px;
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;

  thead {
    background-color: #5a6382;
  }

  thead th {
    height: $pinout-table-row-height;
    border-bottom: 1px $transparent-light-black solid;
  }

  thead th:nth-child(1) {
    width: 10%;
    padding-left: 20px;
  }

  thead th:nth-child(2) {
    width: 20%;
  }

  thead th:nth-child(3) {
    width: 12%;
  }

  thead th:nth-child(4) {
    width: 58%;
  }

  tbody td:nth-child(1) {
    padding-left: 20px;
  }

  tbody td {
    height: $pinout-table-row-height;
    text-align: left;
    border-bottom: 1px $transparent-light-black solid;
    cursor: pointer;
  }

  .pin-color {
    height: 26px;
    width: 26px;
    display: inline-block;
    vertical-align: bottom;
    margin-right: 8px;
  }

  .category-icon {
    width: 25px;
    vertical-align: baseline;
  }
}
