.tippy-box {
  width: 286px;

  .tippy-content {
    min-width: 257px;

    h6 {
      color: $gray;
      margin-bottom: 0;
    }

    h6:nth-child(3) {
      margin-top: 0px;
    }

    .show-history {
      cursor: pointer;

      a {
        color: $brand-blue;
      }

      p {
        color: $brand-blue;
        margin: 0 5px 10px 0;
      }
    }

    .button-container > .btn {
      margin-bottom: 10px;
      width: $button-width;

      &.btn-verified {
        color: $brand-green;
        border-color: $brand-green;
      }

      &.btn-faulty {
        color: $brand-red;
        border-color: $brand-red;
      }
    }
  }
}
