.dependent-dtc-items-list {
  overflow-y: scroll;
  max-height: 300px;
}

.dependent-dtc-item {
  padding: 10px 20px 5px;
  border-bottom: 1px solid lightgrey;
}

.not-relevant-ctrl {
  border-bottom: 1px dotted gray;
  width: 100%;
  text-align: left;
  padding: 5px 8px;
}
