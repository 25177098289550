.virtual-vehicle-configuration-make-model-selection {
  display: flex;
}

.virtual-vehicle-configuration-sales-codes-subtitle {
  font-size: 14px;
  padding-bottom: 8px;
  padding-top: 8px;
  color: white;
}

.virtual-vehicle-configuration-sales-codes-group {
  padding-bottom: 10px;
}

.virtual-vehicle-configuration-sales-codes-group-label {
  color: white;
}
.virtual-vehicle-configuration-sales-codes-group-options {
  display: flex;
}

.virtual-vehicle-configuration-sales-codes-group-button {
  margin: 0 0px 0 0;

  input[type='radio'] {
    display: none;
    position: absolute;
    width: 100%;
    appearance: none;
  }
  input[type='radio']:checked + label {
    background: #1890ff;
    color: #fff;
  }
  label {
    display: block;
    background: white;
    color: black;
    border-radius: 2px;
    padding: 5px 10px;
    margin-right: 5px;
    cursor: pointer;
  }
}

.virtual-vehicle-configuration-sales-code-select-form-container {
  position: relative;
}

#make-model-select-container,
#vin-input-search-container {
  button {
    margin: 1px;
  }
  .ant-collapse-header {
    background: white;
    border-radius: 0;
  }

  .ant-form-item {
    margin-bottom: 0;
  }

  .ant-collapse {
    border-radius: 0;
  }

  .ant-collapse-content {
    border-radius: 0;
  }
}
