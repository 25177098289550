.subsystems-search-button .ant-btn {
  svg {
    fill: black;
    transition: fill 0.4s ease;
  }
}

.subsystems-search-button .ant-btn.ant-btn-background-ghost {
  svg {
    fill: white;
    transition: fill 0.4s ease;
  }
}

.subsystems-search-button .ant-btn-default:hover {
  svg {
    fill: #40a9ff;
  }
}

.subsystems-search-button .ant-btn-default:active {
  svg {
    fill: #096dd9;
  }
}
