@import 'variables';

.dtc-memo-view {
  position: fixed;
  margin-top: 5px;
  width: 600px;
  height: 300px;
  color: black;
  z-index: 2000;
  // IE11 could position view not correctly here.
  right: 230px;
  display: none;
  font-style: normal;
  font-weight: 400;
  line-height: 1.42857;
  line-break: auto;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  white-space: normal;
  font-size: 14px;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 6px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.dtc-memo-view-title {
  padding: 8px 14px;
  margin: 0;
  font-size: 14px;
  color: white;
  background-color: $brand-blue;
  border-bottom: 1px solid #ebebeb;
  border-radius: 5px 5px 0 0;
  display: flex;
  justify-content: space-between;
}

.dtc-memo-view-content {
  padding: 9px 14px;
}

.dtc-memo-view.left > .arrow {
  position: absolute;
  right: -11px;
}

.dtc-memo-view.left > .arrow::after {
  position: absolute;
  top: 100%;
  margin-top: 0;
  margin-left: -11px;
  width: 0;
  height: 0;
  border-width: 6px 0 6px 6px;
  border-style: solid;
  border-color: transparent transparent transparent #fff;
  content: '';
}

.dtc-memo-view-head {
  padding: 10px 20px 10px 20px;
  background-color: $brand-blue;
  border-radius: 4px 4px 0 0;
  color: #ffffff;
}

.dtc-memo-view-label {
  font-size: 13px;
  font-weight: normal;
  color: #7f7f7f;
  margin-bottom: 8px;
}

.dtc-memo-view-body-content {
  max-height: 250px;
  overflow: scroll;
  color: #555;
}

.dtc-memo-view-body-content h1 {
  margin-top: 0;
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.4px;
}

.dtc-memo-view-body-content ul {
  font-size: 14px;
  padding-left: 25px;
}

.dtc-memo-view-body-content blockquote {
  padding: 4px;
  margin-bottom: 10px;
}
