.icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  position: absolute;
  left: 5px;
  top: 3px;
  margin-right: 3px;
}

.clickable {
  cursor: pointer;
}

.disabled {
  border-color: $dark-gray;
  color: $dark-gray;
  cursor: not-allowed;
}

.flex-right {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.flip-h {
  transform: scaleX(-1);
  filter: FlipH;
}

@mixin rotate($rotation) {
  transform: rotate($rotation);
  transform-origin: 50% 50%;
}

.rotate-180 {
  @include rotate(180deg);
}

.rotate-90 {
  @include rotate(90deg);
}

.rotate-270 {
  @include rotate(270deg);
}

.uppercase {
  text-transform: uppercase;
}
