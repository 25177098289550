.react-bs-table {
  table td {
    white-space: normal;
  }
}

.table-container {
  height: calc(100% - 120px);
}

.bs-table-header {
  background: $header-bg;
  color: $blue-black;

  th {
    padding: 20px 5px;
    width: auto;
    font-weight: bold;
  }
}

.bs-table-table {
  background: $white;
  color: $blue-black;
  margin: 0;

  table {
    table-layout: fixed;
  }
}

.table > tbody > tr > td,
.table > thead > tr > th {
  line-height: 32px;
  padding: 7px 5px 7px 20px;
}

.table > thead > tr > th {
  border-bottom: 0;
  font-weight: normal;
}

.bs-table-body {
  background: $white;
  color: $blue-black;
  margin: 0;
  height: 100%;

  td {
    padding: 20px 5px;
    vertical-align: middle;
    outline: none;
    white-space: normal;
    overflow: visible;
    text-overflow: ellipsis;
    width: auto;

    &.react-bs-table-no-data {
      padding: 20px;
    }
  }

  // Stripes in table
  tr:nth-child(even) {
    background-color: #f2f2f2;
  }
}

.bs-table-container {
  height: 100%;
}

#component-history,
#repair-history,
#dtc-single-history,
#users-list,
#component-search-page {
  height: 100%;

  textarea {
    height: 72px;
  }

  .table .table {
    background: $white;
    color: $blue-black;
  }

  .bs-subtable-header {
    display: none;
  }

  .icon-sort {
    margin-left: 5px;
  }

  .icon-arrow {
    margin-left: 10px;
  }
}

#component-history {
  .bs-table-body {
    .bs-subtable-header {
      th {
        padding: 20px 5px;
      }
    }
    .bs-subtable-body {
      tr:first-child {
        td {
          border-top: 0;
        }
      }
      td {
        padding: 20px 5px;
      }
    }
  }

  td:not(.react-bs-table-expand-cell) {
    padding: 0 5px;
  }
}

#dtc-single-history {
  .bs-table-body {
    td {
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }
}

#dtc-single-history {
  .bs-table-header,
  .bs-table-body,
  .bs-subtable-header,
  .bs-subtable-body {
    .col_created_at {
      width: 20%;
      min-width: 180px;
    }
    .col_reason {
      width: 30%;
      padding-left: 20px;
    }
    .col_annotation {
      width: 30%;
      white-space: normal;
    }
    .col_actions {
      width: 250px;
      padding-right: 20px;
    }
  }
}

#component-history {
  .bs-table-header,
  .bs-table-body {
    .col_created_at {
      width: 180px;
      min-width: 180px;
    }
    .col_arrow {
      width: 40px;
    }
    .col_annotation {
      white-space: normal;
    }
    .col_actions {
      width: 250px;
      padding-right: 20px;
    }
  }
}

#repair-history {
  .bs-table-header,
  .bs-table-body {
    .col_created_at {
      width: 10%;
      min-width: 180px;
      padding-left: 20px;
    }
    .col_category {
      width: 10%;
    }
    .col_dtc {
      width: 20%;
    }
    .col_name {
      width: 20%;
    }
    .col_reason {
      width: 10%;
    }
    .col_annotation {
      width: 20%;
      white-space: normal;
    }
    .col_actions {
      width: 10%;
    }
  }

  .Select-menu-outer {
    .Select-option {
      .select-option-label {
        color: black;
        font-weight: normal;
      }
      .select-option-description {
        color: $gray;
        font-weight: normal;
      }
    }
  }
}

#component-history {
  .bs-table-header,
  .bs-table-body {
    .col_fault {
      width: 20%;
    }
  }
}

@media all and (max-width: $screen-lg-min) {
  #repair-history {
    .bs-table-header,
    .bs-table-body {
      .col_actions {
        width: 120px;

        button {
          width: 100%;
          &:not(first-child) {
            margin-top: 5px;
          }
        }
      }
    }
  }
}

@media all and (max-width: 1024px) {
  #repair-history {
    .bs-table-header,
    .bs-table-body {
      .col_created_at {
        width: 8%;
      }
      .col_category {
        width: 8%;
      }
      .col_dtc {
        width: 18%;
      }
      .col_name {
        width: 10%;
      }
      .col_reason {
        width: 28%;
      }
      .col_annotation {
        width: 20%;
      }
      .col_actions {
        width: 8%;
        .action {
          display: inline-block;
          margin: 0;
          margin-right: 0px;
          margin-right: 20px;

          a {
            display: none;
          }
        }
        button {
          width: 100%;
          &:not(first-child) {
            margin-top: 5px;
          }
        }
      }
    }
  }
}
