@mixin loading-text {
  p {
    font-size: 30px;
    padding-bottom: 10px;
    text-align: center;
  }
}

.loading {
  @include loading-text();
  margin-top: 10%;
}

.loading-center {
  @include loading-text();
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
