.fix-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

// Fixes qtip scrolling problem.
// qtip is some component need to highlight selected element of a graph,
// it is a <div> element added to <body> with visibility: hidden property.
// Due to this visibility property, when we scroll graph to far we could see
// scroll control, but we want to hide that part of graph outside of viewport.
// Used only in embedded mode
.fix-qtip-scrolling {
  overflow: hidden;
}
