$divider-color: #49536f;
$dark-bg-color: #212738;

.functional-diagrams-error-boundary-result {
  display: flexbox;
  align-content: center;
  width: 100%;
  height: 100%;
}

.functional-diagrams-page {
  display: flex;
  height: 100vh;
  flex-direction: column;
  .functional-diagrams-page-top-bar {
    display: flex;
    justify-content: space-between;
  }
}

.navbar-vehicle-make-model {
  width: 300px;
}

.navbar-spacer {
  flex-grow: 1;
}

.functional-diagrams-display {
  height: 100%;
  display: flex;
  border-top: 1px solid $divider-color;
  overflow: hidden;
}

.functional-diagrams-left-panel {
  width: 50%;
  height: 100%;
  transition-duration: 300ms;
  transition-property: width;

  &.expand {
    width: 100%;
  }
}

.functional-diagrams-right-panel {
  width: 50%;
  height: 100%;
  background-color: $dark-bg-color;
  border-left: 1px solid $divider-color;
  // Following needed for side drawer.
  position: relative;
  transition-duration: 300ms;
  transition-property: width;

  &.collapse {
    display: none;
  }
}

.functional-diagrams-right-panel-content,
.functional-diagrams-left-panel-content {
  height: 95%;
  .ant-list-item,
  .ant-list {
    color: white;
    border: none;
  }

  .ant-list-header,
  .ant-list-item {
    border-bottom: 1px solid $divider-color !important;
  }
}

.functional-diagrams-left-panel-content {
  display: flex;
  flex-direction: column;
}

.functional-diagrams-left-panel-content-diagram {
  height: 65%;
  flex-grow: 1;
}

.detail-diagram-header {
  height: 5%;
}
.functional-diagrams-right-controls-header {
  height: 54px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .space-stub {
    width: 50px;
  }

  input {
    color: white;
  }

  .ant-input-clear-icon svg {
    fill: white;
  }
}

.functional-diagrams-left-controls-header {
  position: relative;
  height: 5%;
}

.functional-diagrams-collapse-right-btn {
  z-index: 1;
  position: absolute;
  right: 20px;
  top: 46px;
}

.functional-diagrams-filter-selection-info {
  position: absolute;
  right: 70px;
  width: 65px;
  line-height: 12px;
  font-size: smaller;
  color: lightgray;
}

.functional-diagrams-breadcrumb {
  line-height: 20px;
  border-bottom: 1px solid $divider-color;
  padding: 6px 20px;

  .ant-breadcrumb,
  .ant-breadcrumb a,
  .ant-breadcrumb-separator {
    color: gray;
  }

  .ant-breadcrumb a:hover,
  .ant-breadcrumb a:visited {
    color: darkgray;
    text-decoration: none;
  }

  .ant-breadcrumb li:last-child {
    color: white;
  }
}

.functional-diagrams-breadcrumb-back-btn {
  height: 20px;
  width: 20px;
  display: inline-block;
  margin: 0 12px;
  color: white;

  .ant-btn-text:disabled,
  .ant-btn-text:hover:disabled {
    color: gray;
  }

  .ant-btn-text:focus,
  .ant-btn-text:hover {
    color: #1890ff;
  }
}

.functional-diagrams-breadcrumb-wrapper {
  display: inline-block;
}

.functional-diagrams-info-panel {
  display: flex;
  height: 50%;
  flex-direction: column;
  z-index: 1;
  background-color: $dark-bg-color;
  width: 100%;
  border-top: 1px solid #49536f;
  flex-shrink: 0;
}

.functional-diagrams-info-panel-legend {
  display: flex;
  padding-left: 24px;
  border-top: 1px solid $divider-color;
  background-color: hsl(224, 26%, 25%);
  height: 15%;
}

.functional-diagrams-info-panel-details {
  height: 100%;
  overflow-y: scroll;
}

.functional-diagrams-info-panel-details::-webkit-scrollbar {
  width: 10px;
}

.functional-diagrams-info-panel-details::-webkit-scrollbar-track {
  background-color: #2e384f;
}

.functional-diagrams-info-panel-details::-webkit-scrollbar-thumb {
  background-color: #0089e9;
}

.functional-diagrams-loading-padding {
  padding-left: 20px;
  padding-right: 20px;
}

.block-diagram-circuit-category-icon {
  display: flex;
  align-items: center;
  svg {
    height: 20px;
    width: 20px;
    stroke: black;
    path {
      fill: black;
    }
  }
}

.functional-diagrams-empty {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .ant-empty-normal .ant-empty-image,
  .ant-empty-image svg {
    width: 100px;
    height: 100px;
    opacity: 0.9;
  }

  .ant-empty-description {
    color: white;
    opacity: 0.8;
  }
}

.functional-diagrams-detail-info-panel {
  border-top: 1px solid #49536f;
  height: 50%;
  position: relative;
  overflow: hidden;
  background-color: $dark-bg-color;
}

.functional-diagrams-detail-info {
  height: 100%;
}

.functional-diagrams-detail-image {
  height: 100%;
}

.detail-diagrams-circuit-info-component-images {
  display: flex;
  height: 85%;
  width: 100%;
}

.detail-diagrams-component-info {
  display: flex;
  height: 85%;
  width: 100%;
}
.detail-diagrams-circuit-info-from-component {
  width: 50%;
}

.detail-diagrams-circuit-info-to-component,
.detail-diagrams-component-info-table {
  width: 50%;
}

.functional-diagrams-detail-pin-info-table {
  width: 50%;

  &.expand {
    width: 70%;
  }
}

.functional-diagrams-detail-pin-info-image-divider {
  z-index: 1;
  position: absolute;
  right: 20px;
  top: 20px;
}

.functional-diagrams-detail-pin-info-image-panel {
  position: relative;
  width: 50%;
  border-left: 1px solid #49536f;
  display: flex;
  &.minimize {
    width: 30%;
  }
}

.functional-diagrams-detail-pin-info-image {
  width: 100%;
}

.detail-diagrams-circuit-info-to-component {
  border-left: 1px solid #49536f;
}

.detail-diagrams-component-info-image {
  width: 50%;
  border-left: 1px solid #49536f;
}

.functional-diagrams-loading {
  display: 'flex';
  height: '100%';
  align-items: 'center';
  justify-content: 'center';
}

.width-50 {
  width: 50%;
}

.height-50 {
  height: 50%;
}

.functional-diagrams-page-top-bar-logo {
  width: 16.66%; // To sync with Tracer's top bar logo width.
  min-width: 170px;
}

.functional-diagrams-page-top-bar-logo-image {
  width: 100%;
}

.functional-diagrams-block-diagram-image {
  height: 50%;
}

.functional-diagrams-detail-pin-info {
  display: flex;
  height: 100%;
  width: 100%;
}

.functional-diagrams-component-or-location-view {
  height: 100%;
}

.functional-diagrams-component-or-location-view-image {
  height: 80%;
}

.functional-diagrams-component-or-location-view-ctrls {
  height: 20%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-top: 1px solid #49536f60;
}

.functional-diagrams-button-group {
  width: 30%;
  display: flex;
  justify-content: space-between;
}

.circuit-color-icon {
  height: 20px;
  width: 20px;
}

.functional-diagrams-sales-codes {
  width: 100vw;
  display: flex;
  flex-direction: column;
  padding: 24px;
  padding-left: 50px;
}

.functional-diagrams-sales-codes-input {
  padding-top: 24px;
}

.functional-diagrams-sales-codes-submit {
  margin-top: 24px;
  width: 10%;
}

.functional-diagrams-make-model-select {
  width: 30%;
}

.subsystems-search-button .ant-btn {
  svg {
    fill: black;
    transition: fill 0.4s ease;
  }
}

.subsystems-search-button .ant-btn.ant-btn-background-ghost {
  svg {
    fill: white;
    transition: fill 0.4s ease;
  }
}

.subsystems-search-button .ant-btn-default:hover {
  svg {
    fill: #40a9ff;
  }
}

.subsystems-search-button .ant-btn-default:active {
  svg {
    fill: #096dd9;
  }
}

.functional-diagrams-info-panel .ant-tabs-content,
.functional-diagrams-info-panel .ant-tabs-tabpane {
  height: 100%;
}

.ant-tabs-tab-btn {
  color: white;
}

.ant-tabs-tab-btn:hover {
  color: #40a9ff;
}

.fuse-details-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.location-image-container,
.fuse-color-image-container {
  height: 100%;
  width: 50%;
}
.fuse-color-image-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.fuse-color-image-container .connector-preview-box {
  color: white;
  opacity: 1;
  position: static;
}

.fuse-color-image-container .svg-viewer {
  transform: scale(2);
  padding-left: 10%;
}
