.vehicle-configuration-sales-codes-select {
  overflow-y: scroll;
}

#vehicle-configuration-page {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 60px);
  margin-right: -10px;
  margin-left: -10px;
}

.vehicle-configuration-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 90%;
}

.vehicle-configuration-left-panel {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 1%;
  padding-left: 5%;
  padding-right: 5%;
  border-top: 1px solid transparent;
  border-top-color: #49536f;
}

.vehicle-configuration-center-panel {
  border-left: 1px solid transparent;
  border-left-color: #49536f;
  height: 100%;
}

.vehicle-configuration-right-panel {
  width: 50%;
  height: 100%;
  padding-top: 1%;
  padding-left: 5%;
  padding-right: 5%;
  display: flex;
  flex-direction: column;
  border-top: 1px solid transparent;
  border-top-color: #49536f;
}

.vehicle-configuration-bottom-panel {
  display: flex;
  background-color: #212738;
  width: 100%;
  height: 10%;
  min-height: 64px;
  align-items: center;
  gap: 1%;
  padding-left: 20px;
  border-top: 1px solid transparent;
  border-top-color: #49536f;
}
