.vehicle-test-result-navigation {
  font-size: 16px;
  height: 40px !important; // important needs to fix .tab-pane > div height: inherit
  width: 250px;
  position: fixed;
  right: 50%;
  bottom: 10px;
  z-index: 6000;
  display: flex;

  &.disabled,
  &.disabled > a {
    color: gray;
    pointer-events: none;
  }

  &.prev {
    border: 2px solid white;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    padding-left: 10px;

    &.disabled {
      border-color: gray;
    }

    &:before {
      content: '←';
      display: flex;
      align-items: center;
    }
  }

  &.next {
    transform: translateX(250px);
    border: 2px solid white;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    padding-right: 10px;

    &.disabled {
      border-color: gray;
    }

    &:after {
      content: '→';
      display: flex;
      align-items: center;
    }
  }
}

.vehicle-test-result-navigation-btn {
  margin: auto;
  width: 100%;
  text-align: center;
  line-height: 40px;

  &:hover {
    text-decoration: none;
  }
}
