// Constants

$connector-preview-box-width: 150px;
$connector-preview-box-height: 200px;
$connector-preview-box-title-height: 20px;

.connector-preview-box {
  // We might position on the top
  //top: 60px;
  bottom: 70px;
  left: 20px;
  width: $connector-preview-box-width;
  height: $connector-preview-box-height;
  border-radius: 4px;
  position: absolute;
  background-color: #212738;
  opacity: 0.9;

  svg {
    width: $connector-preview-box-width;
    height: $connector-preview-box-height - $connector-preview-box-title-height;
  }
}

.connector-preview-box-title {
  text-align: center;
  max-width: $connector-preview-box-width;
  height: $connector-preview-box-title-height;

  * {
    vertical-align: middle;
  }
}

.connector-preview-box-title-text {
  display: inline-block;
}
