.port {
  fill: #3e405a;
  stroke: #475872;
  stroke-width: 1pt;
  shape-rendering: geometricPrecision;

  &:not(.disabled) {
    cursor: pointer;

    &.active {
      fill: #0089e9;
      stroke-width: 1px;
    }

    &:hover {
      stroke: #a5a9e2;
    }
  }
}
.port-label {
  fill: #d6e7ff;
  pointer-events: none;
  font-size: 9px;
  text-rendering: geometricPrecision;
  user-select: none;
}
