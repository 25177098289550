.rect {
  fill: #2b2c3e;
  transition: stroke 100ms ease-in-out;
  stroke: #475872;
  shape-rendering: geometricPrecision;
  stroke-width: 1pt;

  &:not(.selectionDisabled):not(.disabled) {
    cursor: pointer;

    &:hover {
      stroke: #a5a9e2;
    }

    &.dragging {
      stroke: #a5a9e2;
    }

    &.active {
      fill: #0089e9;
    }

    &.unlinkable {
      stroke: #ff005d;
    }

    &.deleteHovered {
      stroke: #ff005d !important;
      stroke-dasharray: 4 2;
    }
  }

  &:focus {
    outline: none;
  }

  &.children {
    fill: transparent;
    stroke: #475872;
  }
}

.splice {
  stroke: none;
  fill: transparent;
}
