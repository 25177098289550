@import '~trix/dist/trix.css';

// Trix editor custom styles.

// Hide file attachment controll.
.trix-button-group--file-tools {
  display: none !important;
}

// Hide link button
.trix-button--icon-link {
  display: none;
}

.trix-wrapper {
  background: white;
  color: black;
  padding: 20px;
}
