#pin-table {
  // Override the positioning so the gradient does not overlay on the table
  height: 100%;
  position: relative;
  z-index: map-get($zindex, header);
}

#connector-modal {
  z-index: 100000;
}

.pinout-header {
  margin-bottom: 20px;
}

#pinout {
  .report-table-row {
    background-color: $blue-black-report;
    font-size: 13px;
    font-weight: 300;
  }

  .report-table-header {
    background-color: $transparent-white;

    thead tr th {
      font-size: 13px;
      font-weight: 300;
      border-bottom: none;
    }
  }

  .report-table-body {
    // Row divider style
    tbody tr td {
      border-top: 1px solid $transparent-white;
    }
  }

  // TODO make this better
  button {
    position: absolute;
    right: 10px;
  }

  .pins-scrollbox {
    overflow-x: scroll;
  }

  .pins-wrapper {
    display: table;
    width: 100%;
  }

  .connector {
    height: 45px;
    border: solid 2px #7c8d98;
    color: $white;
    text-align: center;

    p {
      line-height: 40px;
      margin: 0px;
      position: absolute;
      left: calc(50% - 20px);
    }
  }

  .connector-incoming {
    @extend .connector;
    margin-bottom: 10px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .connector-outgoing {
    @extend .connector;
  }
}

.pin {
  cursor: pointer;
  margin-left: 7px;
  margin-right: 7px;

  p {
    margin: 0;
  }
}

#pins {
  display: flex;
  justify-content: flex-start;
}

.circuit-category-img {
  width: 25px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;

  g path {
    fill: black;
  }
}
