.user-menu,
.vehicle-make-model-menu {
  .collapsible {
    height: 60px;
    min-width: 100px;
    display: flex;
    align-items: center;

    .username,
    .vehicle-name {
      display: flex;
      align-items: center;

      img {
        margin-left: 0.5rem;
      }
    }

    .username {
      font-weight: $bold;
    }

    .vehicle-name {
      white-space: nowrap;
    }

    .toggle-icon {
      height: 20px;
    }
  }
}

.user-menu {
  padding-right: 20px;
  float: right;
  cursor: pointer;

  .collapsible {
    justify-content: flex-end;
  }
}

.vehicle-make-model-menu {
  padding-left: 20px;
  cursor: pointer;

  p {
    margin: 0px;
  }
}

.make-model-label {
  color: #90939bff;
}

#user-menu-popover {
  width: 300px;
  z-index: map-get($zindex, overlay) !important;

  .popover-content {
    padding: 0px;
  }

  .content-row {
    padding: 15px 30px;
    color: $black;
    font-weight: 300;
  }

  .username {
    padding: 24px 30px;
    font-weight: $bold;
    word-wrap: break-word;
  }

  .separator {
    height: 2px;
    margin: 0;
    background-color: $light-gray;
  }

  .tracer-version {
    color: $gray;
    font-size: 12px;
    text-align: center;
  }
}

#change-start-view-form {
  input[name='changeStartView'] {
    margin: 2px 0 0 -20px;
  }
}
