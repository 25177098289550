.icon-edit {
  background: url('../../images/ic-edit.png') 0 0 no-repeat;
  width: 24px;
  height: 24px;
  display: block;
}

.icon-delete {
  background: url('../../images/ic-delete.png') 0 0 no-repeat;
  width: 24px;
  height: 24px;
  display: block;
}

.icon-search {
  background: url('../../images/ic-search.png') 0 0 no-repeat;
  width: 24px;
  height: 24px;
  display: block;
}
