.vehicle-view-faulty-harness {
  position: absolute;
  left: 10px;
  top: 5px;
}

.vehicle-view-add-fault {
  position: absolute;
  right: 10px;
  bottom: 10px;
}
