.divider {
  display: flex;
  align-items: center;
  color: white;
  margin: 16px 0;
  white-space: nowrap;
  text-align: center;
  border-top: 0;
  border-top-color: #49536f;

  &::before,
  &::after {
    position: relative;
    width: 50%;
    border-top: 1px solid transparent;
    // Chrome not accept `inherit` in `border-top`
    border-top-color: inherit;
    border-bottom: 0;
    transform: translateY(50%);
    content: '';
  }
}

.divider-inner-text {
  display: inline-block;
  padding: 0 5px;
}
