@import 'utility';

.component-fault-history-group {
  color: #000000;
  display: flex;
  flex-direction: column;
  min-height: 80px;

  .cell {
    flex: 1;
  }

  .header-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 25px;
    min-height: 80px;

    &:hover {
      @extend .clickable;
    }

    .fault-type-container {
      flex: 1;

      .fault-type-name {
        @extend .modal-prominent-text;
      }
    }

    .last-time-applied {
      flex: 1;
    }

    .annotation-count-container {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .no-comments {
        color: $gray;
        font-size: 14px;
      }

      .annotation-count {
        padding-left: 10px;
      }
    }
  }

  .history-item {
    display: flex;
    min-height: 80px;
    background-color: #f3f3f3;
    padding-left: 55px;
    align-items: center;

    .vin {
      font-weight: 600;
    }

    .title {
      font-size: 14px;
      color: $black;
    }

    .last-time-applied {
      font-size: 14px;
      color: $black;
    }

    .annotation {
      padding-right: 20px;
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }
}
