.jd-manual-alerts {
  .ant-pagination-item a {
    color: white;
  }

  .ant-pagination-item-active a {
    color: black;
  }

  .ant-pagination-prev,
  .ant-pagination-next {
    background-color: white;
  }
}
