@import 'partials/variables';
@import 'partials/embedded-mode/variables';
@import 'partials/typography';
@import 'partials/zindex';

@import 'bootstrap-custom/boostrap-custom';
@import 'partials/font-faces';
@import 'react-widgets/react-widgets-custom';
@import 'trix-editor/trix-editor';

// Hide scrollbars in Chrome
::-webkit-scrollbar {
  width: 0;
}

html,
body {
  // IE Fix - Otherwise the scrollbar will overlap content
  -ms-overflow-style: scrollbar;
  height: 100%;
  //overflow: hidden;
  position: absolute;
  width: 100%;
  text-rendering: optimizeSpeed;
}

textarea {
  resize: none;
}

a {
  &:focus,
  &.focus,
  &:active:focus,
  &:active.focus,
  &.active:focus,
  &.active.focus {
    outline: none;
  }
}

.form-inline {
  input,
  select {
    margin-right: 10px;
  }
}

.main-content {
  height: calc(100vh - #{$navbar-height});
  touch-action: none;
}

.row-no-padding {
  [class*='col-'] {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.row-no-margin {
  margin-left: 0;
  margin-right: 0;
}

[class*='col-'] {
  height: 100%;
}

.flex-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  align-content: flex-start;
}

.flex-item {
  flex-grow: 0;
}

.title {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.4px;
  margin-right: 10px;
}

// Component list-item buttons
.button-container {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-top: 20px;
  width: 100%;
  .btn {
    width: 105px;
    height: 32px;
    border: 0;
    background-color: rgba(255, 255, 255, 0.8);
    font-weight: bold;
    padding: 7px;
  }

  .btn-faulty {
    color: $brand-red;
    margin-right: 5px;
  }

  .btn-verified {
    background-color: rgba(255, 255, 255, 0.8);
    color: $brand-green;
    margin-left: 5px;
  }

  .btn-outline {
    border: 1px solid;
    border-radius: 16px;
  }

  .btn-cantfix {
    color: $brand-red;
    position: relative;
    width: 130px;
  }

  .btn-disabled {
    pointer-events: none;
    cursor: pointer;
  }
}

.title-container {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center; // Pull the last image to the right of the container
  .icon-info {
    margin-left: auto;
  }
  .icon-chevron {
    margin-left: auto;
  }
}

.list-group-item > .title-container {
  margin-bottom: 6px;
}

@mixin text-overflow-ellipsis() {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.subtitle {
  font-size: 10px;
  opacity: 0.8;
  letter-spacing: 0.2px;
  @include text-overflow-ellipsis();
}

// Maximize tab content height so graph component is rendered properly
.tab-content {
  height: 100%;
  overflow-y: hidden;
  .tab-pane {
    height: inherit;
    > div {
      height: inherit;
    }
  }
}

.header {
  z-index: map-get($zindex, header);
  position: absolute;
  width: 100%;
  h5 {
    margin-bottom: 0px;
  }
  &.header-relative {
    position: relative;
  }
}

h6 {
  font-size: 12px;
}

.error-message {
  position: absolute;
  width: 100%;
  text-align: center;
  top: 30%;
  p {
    font-size: 16px;
    opacity: 0.8;
    margin: 20px 100px 20px 100px;
  }
  h2 {
    margin-bottom: 20px;
  }
  svg {
    width: 100%;
  }
}

.verified {
  .btn-verified {
    background-color: $white;
    color: $brand-green;
  }
}

@import 'partials/button-large';
@import 'partials/controls';
@import 'partials/demo';
@import 'partials/dtc-dependency';
@import 'partials/error';
@import 'partials/gradient';
@import 'partials/harnessdigraph';
@import 'partials/icon';
@import 'partials/left-sidebar';
@import 'partials/loading';
@import 'partials/login';
@import 'partials/logout';
@import 'partials/main-dashboard';
@import 'partials/modal';
@import 'partials/more-menu';
@import 'partials/navigation';
@import 'partials/notification';
@import 'partials/pindetails';
@import 'partials/pinout';
@import 'partials/qrcode';
@import 'partials/quiz';
@import 'partials/reports';
@import 'partials/sidepanel';
@import 'partials/table';
@import 'partials/tooltip';
@import 'partials/topology';
@import 'partials/utility';
@import 'partials/vehicle_test_search';
@import 'partials/vin';
@import 'partials/zoom';
@import 'partials/activity-indicator';
@import 'partials/user-menu';
@import 'partials/component-list-item';
@import 'partials/same-issues-history';
@import 'partials/dtc-memo-view';
@import 'partials/dtc-popover';
@import 'partials/fuses-and-relays';
@import 'partials/users-administration';
@import 'partials/cant-fix';
@import 'partials/dtc-memo';
@import 'partials/no-graph';
@import 'partials/repair-history';
@import 'partials/title-bar';
@import 'partials/dtc-memo-and-mapping-edit';
@import 'partials/add-connector-mapping';
@import 'partials/embedded-mode/mixins';
@import 'partials/embedded-mode/pan';
@import 'partials/embedded-mode/layout';
@import 'partials/embedded-mode/zoom';
@import 'partials/embedded-mode/action-bar';
@import 'partials/embedded-mode/pinout-view';
@import 'partials/embedded-mode/controls';
@import 'partials/embedded-mode/harness-view-vehicle-view-switch';
@import 'partials/embedded-mode/dtc';
@import 'partials/embedded-mode/select';
@import 'partials/embedded-mode/pindetails';
@import 'partials/embedded-mode/fixes';
@import 'partials/embedded-mode/table';
@import 'partials/embedded-mode/switch-test-view';
