.text-danger {
  color: #a94442;
  position: absolute;
}

#error-screen {
  font-size: 30px;
  margin-top: 10%;
  text-align: center;
}

.form-text-error {
  color: #a94442;
  font-weight: bold;
}
