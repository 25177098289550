$counter-diameter: 25px;
.round-counter {
  width: $counter-diameter;
  height: $counter-diameter;
  border-radius: $counter-diameter / 2;
  background-color: $brand-orange;
  color: $white;
  position: absolute;
  font-size: $small-font-size;
  font-weight: $font-bold;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-same-issues {
  padding: 0;
  width: 85%;
  left: 7.5%;
  height: 90%;
  top: 5%;

  .panel {
    background-color: $light-blue-black;
  }

  .panel-heading {
    height: auto;
  }

  .panel-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
  }

  .panel-body {
    display: flex;
    align-content: center;
    background-color: $light-blue-black;
    height: 90%;
  }

  .big {
    font-family: $font-family-sans-serif;
    font-weight: 600;
    font-size: 16px;
  }

  .subtitle {
    font-size: 12px;
    color: $gray;
    margin-bottom: 0;
  }

  @mixin select-branding() {
    background-color: $brand-dark-blue;
    color: $white;
    font-weight: 100;
    box-shadow: none;
  }
  @mixin select-border() {
    border-color: #d9d9d9 #ccc #b3b3b3;
  }
  @mixin form-control() {
    @include select-branding();
    @include select-border();
    display: flex;
    justify-content: left;
    align-items: center;
  }
  .select-previous-test-case {
    width: 50%;
    margin: 0 auto;
    color: $white;
    // This has a z-index of 7000 because the header style used in the vehicle view has a z-index of 6000 because the gradient-top
    // of the vehicle view has a z-index of 5000 🤦‍
    z-index: map-get($zindex, button);
    @include select-border();

    .select {
      color: $blue-black;
    }

    .Select-control,
    .Select-multi-value-wrapper {
      @include select-border();
      @include select-branding();
    }

    .Select-placeholder {
      @include form-control();
    }

    .Select-value {
      @include form-control();
      .Select-value-label {
        color: $white !important;
        padding: 10px;
        font-size: 1.2rem;

        .vin {
          font-size: 1.2em;
          font-weight: 400;
          margin-left: 10px;
        }
      }
    }

    .Select-option {
      @include select-branding();
      &.is-selected,
      &.is-focused {
        background-color: $brand-blue;
      }
    }

    .Select-menu-outer {
      @include select-branding();
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      z-index: map-get($zindex, overlay);

      .Select-menu {
        @include select-branding();
      }
    }

    .Select-arrow {
      border-color: $white transparent transparent !important;
    }
  }

  .fault-source-detail {
    padding: 30px;
    background-color: $white;
  }

  .related-incidents {
    position: relative;

    .incident-count {
      @extend .round-counter;
      position: relative;
      margin-top: 10px;
    }
  }

  .fault-type-name {
    @extend .modal-prominent-text;
  }

  .no-comments {
    color: $gray;
    font-size: 14px;
  }

  .table-footer-container {
    flex: 1;
    display: flex;
    height: 48px;
    justify-content: center;
    align-items: center;
    padding-left: 30px;
    border-top: 1px solid $light-gray;
    color: $brand-blue;

    .button-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 90px;

      svg {
        stroke: $brand-blue;
      }
    }
  }

  .vehicle-view {
    height: 100%;
    padding: 0;
    background-color: $light-blue-black;
    color: $white;

    // TODO: consider to remove this
    .zoom-control {
      left: 30px;
      bottom: 40px;
    }

    #topology-svg-container {
      height: 70%;
    }

    .header {
      position: relative;
      padding-top: 20px;
      padding-bottom: 10px;
    }

    svg {
      height: 100%;
    }

    .error-message {
      position: relative;
      height: 100%;
      background-color: $light-blue-black;

      svg {
        width: 160px;
        height: 160px;
      }
    }
  }
}

.same-issues-history-button-container:active {
  transform: translateY(3px);
}

.same-issues-history-button-container {
  width: 54px;
  height: 54px;
  border-radius: 27px;
  background-color: $brand-blue;
  position: absolute;
  bottom: 40px;
  right: 40px;
  z-index: map-get($zindex, overlay) !important;
  border: none;
  box-shadow: 0 0 20px 10px $brand-blue-transparent;
  animation: pulsate 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  outline: none;

  .same-issues-history-button {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background-color: $brand-blue;
    align-items: center;
    justify-content: center;
    display: flex;
  }

  .counter {
    @extend .round-counter;
    z-index: map-get($zindex, overlay);
    right: 0px;
    bottom: 40px;
    box-shadow: 0 2px 4px 0 $transparent-black;
  }

  @keyframes pulsate {
    0% {
      box-shadow: 0 0 0 $brand-blue-transparent;
    }
    50% {
      box-shadow: 0 0 20px 10px $brand-blue-transparent;
    }
    100% {
      box-shadow: 0 0 0 $brand-blue-transparent;
    }
  }
}
