.fuses-and-relays-container {
  color: $white;
  display: flex;
  align-items: flex-start;

  .power-distribution-box-image {
    height: 100%;
    width: 100%;
    flex: 2;
    align-items: center;
    justify-content: center;
  }

  .units {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-top: 50px;

    .unit {
      margin-top: 20px;

      .unit-title {
        font-size: 18px;
        font-weight: bold;
      }
    }
  }
}
