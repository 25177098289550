#global-search-page {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 60px);
  margin-right: -10px;
  margin-left: -10px;
}

.global-search-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 90%;
}

.circuit-details-expand {
  margin-left: 30px;
}

.global-search-left-panel {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 1%;
  padding-left: 5%;
  padding-right: 5%;
  border-top: 1px solid transparent;
  border-top-color: #49536f;
}

.location-pinout-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.location-pinout-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.global-search-center-panel {
  border-left: 1px solid transparent;
  border-left-color: #49536f;
  height: 100%;
}

.global-search-right-panel {
  width: 50%;
  height: 100%;
  padding-top: 1%;
  padding-left: 5%;
  padding-right: 5%;
  display: flex;
  flex-direction: column;
  border-top: 1px solid transparent;
  border-top-color: #49536f;
}

.global-search-bottom-panel {
  display: flex;
  background-color: #212738;
  width: 100%;
  height: 10%;
  min-height: 64px;
  align-items: center;
  gap: 1%;
  padding-left: 20px;
  border-top: 1px solid transparent;
  border-top-color: #49536f;
}

.component-location-or-pinout {
  height: 65%;
  flex-grow: 0.7;
}

.component-location-or-pinout-title {
  height: 5%;
}

.component-location-or-pinout-toggle {
  height: 5%;
}

.component-location-or-pinout-view {
  height: 90%;
}

.empty-description {
  color: white;
}

.empty {
  display: flex;
  height: 50%;
  align-items: center;
  justify-content: center;
}

.search-input,
.category-toggle {
  height: 5%;
}

.search-results {
  height: 90%;
}

.inline-color-icon {
  display: inline-block;
  vertical-align: middle;
}

.global-search-results-table {
  height: 100%;
}

.search-panel {
  height: 100%;
}

.global-search-related-subsystem-button {
  display: inline-block;
}

.global-search-related-subsystem-button .subsystems-search-button .ant-btn {
  background-color: white;
}

.global-search-related-subsystem-button
  .subsystems-search-button
  .ant-btn.ant-btn-background-ghost {
  background-color: #49536f;
}

.global-search-component-overlay {
  position: absolute;
  top: 50%;
  left: 40%;
}
