.action-bar {
  margin-left: auto;
  display: flex;
  flex-direction: column;

  div {
    background-color: $embedded-mode-control-background-color;
    border-style: solid;
    border-color: $embedded-mode-page-background-color;
    border-width: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: $embedded-mode-control-height;
    width: $embedded-mode-control-width;
  }

  div.selected {
    background-color: #0089e9;
  }
  div:active {
    background-color: $embedded-mode-control-active;
  }
  div.disabled-control {
    path {
      fill: $embedded-mode-disabled;
    }
  }
}
