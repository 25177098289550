@import '../assets/styles/partials/variables';

#reports-page {
  color: $blue-black;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: left;
  align-items: stretch;
  align-content: stretch;

  .left {
    width: 700px;
    padding: 45px;
  }
  .right {
    max-width: 900px;
    padding: 45px;
  }
  .report-control {
    width: 100%;
    label {
      color: #212738;
    }
  }
  .plant {
    display: flex;
    padding: 20px;
    align-items: center;
    height: 50px;
    width: 100%;
    background-color: white;
    border-bottom: 1px solid #e7e8ed;
    button {
      background-color: transparent;
      border: none;
      cursor: pointer;
      text-decoration: underline;
      display: inline;
      margin: 0;
      padding: 0;
    }
    button:hover {
      text-decoration: none;
    }
  }
  form {
    padding: 20px;
    background-color: white;
    color: black;
  }
  .form-buttons {
    display: flex;
    justify-content: space-between;
  }
  table {
    width: 100%;
    table-layout: fixed;
    overflow: hidden;
    color: black;
    background-color: white;

    thead {
      vertical-align: middle;
      background-color: $brand-light-blue;
      color: black;
    }

    th,
    td {
      padding: 10px 20px 10px 20px;
      border: 2px solid #4b566a;
      border-top: 1px solid #e7e8ed;
      font-weight: normal;
      border-style: none;
      white-space: pre-line;
      overflow: hidden;
    }
  }
  #fault-results {
    margin-top: 20px;
  }

  .selected-fault {
    div {
      height: 50px;
      padding: 20px;
    }
    margin-top: 25px;
    background-color: white;
  }
  #report-vehicle-tests {
    a {
      color: $brand-blue;
    }
  }

  #component-faults,
  #harness-faults,
  #device-faults,
  #other-faults {
    thead tr th:first-child,
    tbody tr td:first-child {
      width: 5%;
    }
    thead tr th:last-child,
    tbody tr td:last-child {
      width: 15%;
    }
  }

  #failures-overview {
    font-weight: normal;
    color: white;
    margin-top: 20px;
    border: 2px solid #4b566a;
    .failures-day:not(:last-child),
    .failure-hour:not(:last-child) {
      border-bottom: 2px solid #4b566a;
    }
    .failure-date {
      /* top | horizontal | bottom */
      padding: 10px 20px 0px;
    }
    .failure-hour {
      display: flex;
      padding: 10px 40px 0px;
      .time-check-box {
        min-width: 150px;
        padding-top: 5px;
      }
    }
    .failures-count {
      padding: 20px 0px;
      display: flex;
      justify-content: space-around;
      border-bottom: 2px solid #4b566a;
      .count-type {
        background-color: hsla(0, 0%, 60%, 0.15);
        padding: 5px;
        border-radius: 100px;
      }
    }
  }
  .fault-chart {
    width: 100%;
    height: 50px;
  }
  .harness-dot {
    height: 10px;
    width: 10px;
    background-color: #ffc000;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
  }
  .component-dot {
    height: 10px;
    width: 10px;
    background-color: #0189e9;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
  }
  .other-dot {
    height: 10px;
    width: 10px;
    background-color: #d74053;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
  }
  .device-dot {
    height: 10px;
    width: 10px;
    background-color: #9ba52a;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
  }
  .no-faults {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    border-radius: 4px;
    margin-top: 20px;
    border: 2px solid #4b566a;
    height: 80px;
  }
  #selected-periods-info {
    height: 50px;
    background-color: white;
    display: flex;
    align-items: center;
    padding: 20px;
    justify-content: space-between;
  }
}
