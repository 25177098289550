#digraph {
  // Need to keep the graph at it's max size so that cytoscape renders it correclty
  height: 100%;
  width: 100%;
}

#app-layout {
  height: 100%;
  width: 100%;
  min-width: 500px;
  margin: 0;

  .nav-tabs > li,
  .nav-pills > li {
    float: none;
    display: inline-block;
    *display: inline; /* ie7 fix */
    zoom: 1; /* hasLayout ie7 trigger */
  }

  .nav-tabs,
  .nav-pills {
    text-align: center;
    margin: 10px 0;
  }
}
