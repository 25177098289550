@import '../../partials/variables';

.ms-dropdown {
  ::-webkit-scrollbar-button {
  }
  ::-webkit-scrollbar {
    width: 6px;
    border-radius: 3px;
  }
  ::-webkit-scrollbar-thumb {
    width: 6px;
    border-radius: 3px;
    background-color: #aaa;
  }
  ::-webkit-scrollbar-track {
    width: 6px;
    border-radius: 3px;
    background-color: #fff;
  }

  .dropdown {
    width: 100%;

    .ms-dropdown-button {
      text-align: left;
      position: relative;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding-right: 30px;
      background-color: $white;
      border-color: #d9d9d9 #ccc #b3b3b3;
      border-radius: 4px;
      border: 1px solid #ccc;
      color: $blue-black;
      cursor: default;
      display: table;
      border-spacing: 0;
      border-collapse: separate;
      height: 36px;
      outline: none;
      width: 100%;

      &.placeholder {
        color: #aaa;
      }

      .caret {
        position: absolute;
        right: 10px;
        top: 15px;
        border-color: #999;
        color: #999;
        border-top: 5px dashed;
        border-right: 5px solid transparent;
        border-left: 5px solid transparent;
        border-bottom: none;
        vertical-align: middle;
      }

      &.open {
        background: none;
        background-color: $white;
        color: $blue-black;
        border-color: #d9d9d9 #ccc #b3b3b3;
        border: 1px solid #ccc;
        box-shadow: none;
        -webkit-box-shadow: none;

        .caret {
          border-top: none;
          border-bottom: 5px dashed;
        }

        &.placeholder {
          color: #aaa;
        }
      }

      &:active {
        box-shadow: none;
        -webkit-box-shadow: none;
      }

      &:hover,
      &:focus {
        background: none;
        background-color: $white;
        border-color: #d9d9d9 #ccc #b3b3b3;
        border: 1px solid #ccc;
        &.placeholder {
          color: #aaa;
        }
      }

      &.labeled {
        &:not(.placeholder) {
          height: auto;
          padding: 4px 30px 4px 4px;
        }
        &.placeholder {
          height: 36px;
        }
        .label {
          padding: 6px;
          background: $brand-blue;
          display: inline;
          border-radius: 4px;
          margin: 2px;
          color: $white;
          float: left;
        }
      }
    }
  }

  .dropdown-menu {
    width: 100%;
    padding: 10px;

    .ms-list-container {
      max-height: 200px;
      overflow-y: auto;
    }
    .search-container {
      width: 100%;

      .search-field-wrapper {
        padding: 4px 0;
        .search {
          display: inline-block;
          background: url('../../../images/ic-search.png') 6px 4px no-repeat;
          padding-left: 32px;
        }
      }
    }
    .ms-dropdown-menu-item {
      margin: 7px 0;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }

      input[type='checkbox'] {
        width: 17px;
        height: 17px;
        display: inline-block;
        position: relative;
        top: 4px;
      }
      label {
        display: inline-block;
        cursor: pointer;
        color: $blue-black;
        font-weight: normal;
        margin: 0 0 0 10px;
        padding: 0;
      }
      a {
        margin: 0;
        padding: 0;
        display: block;
      }
      &.active {
        a {
          color: $blue-black;
          background: none;

          &:active,
          &:hover,
          &:focus {
            color: $blue-black;
            background: none;
          }
        }
      }
      &.with-children {
        label {
          margin-left: 27px;
        }
        .caret {
          position: absolute;
          right: 10px;
          border-color: #999;
          color: #999;
          border-left: 5px dashed;
          border-top: 5px solid transparent;
          border-bottom: 5px solid transparent;
          border-right: none;
          vertical-align: middle;
        }
        .ms-list-container {
          position: absolute;
          left: 70%;
          width: 100%;
          z-index: 100;
          -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
          -moz-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
          box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
          padding: 15px;
          margin-top: -20px;
          background: $white;
          display: block;
        }
      }
    }
  }
}
