.btn {
  border: $global-border-width solid $white;
  z-index: map-get($zindex, button);

  &.active {
    background-color: $white;
    color: $blue-black;
    @include box-shadow(none);
  }

  &:focus,
  &.focus,
  &:active:focus,
  &:active.focus,
  &.active:focus,
  &.active.focus {
    outline: none;
  }

  &.btn-default {
    border: $global-border-width solid $brand-blue;
  }

  &.btn-secondary {
    border: $global-border-width solid $white;
    background-color: transparent;
  }
}

// Fix duplicate borders for justified btn group
.btn-group-justified .btn {
  &:not(:first-child) {
    border-left-width: 0;
  }
  &:last-child {
    border-right-width: $global-border-width;
  }

  &.disabled {
    @extend .disabled;
  }
}

.btn-large p.btn-large-text {
  -webkit-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
