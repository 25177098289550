$input-height: 36px; // at 16px, this an even 40px
$text-color: #333;
$multiselect-tag-color: '#333 !important';
$list-text-color: #333;
$multiselect-tag-padding-left: 1em;
$multiselect-tag-gutter: 5px;
$multiselect-gutter-pt: 0.5;

$font-path: '~react-widgets/lib/fonts';
$img-path: '~react-widgets/lib/img';
$rw-font-path: '~react-widgets/lib/fonts';
$rw-img-path: '~react-widgets/lib/img';

@import '~react-widgets/lib/scss/react-widgets';

.rw-multiselect {
  .rw-input-reset {
    margin-top: 9px !important;
    padding-left: 5px;
  }
  .rw-multiselect-tag-btn {
    font-size: 20px;
    margin-left: 0.25em;
    font-size: 21px;
    padding-top: -4px;
    line-height: 1;
  }
}
