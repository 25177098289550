.more-menu {
  width: 16px;
  height: 32px;
  border: 1px solid gray;
  border-radius: 8px;
  background-color: rgba(90, 99, 130, 0.3);
  display: flex;
  cursor: pointer;

  &.active {
    background-color: rgba(90, 99, 130, 0.5);
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .more-menu-icon {
    display: block;
    height: 20px;
    margin: auto;
    width: 16px;
  }
}

.more-menu-list {
  background-color: $blue-black;
  border-radius: 4px;
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.3);
  list-style: none;
  margin-top: 5px;
  padding-inline-start: 0;
  position: fixed !important;
  min-width: 265px;
  // MoreMenu.js has also max-height value that set in javascript
  // code because that value used in code logic to display menu upwards / downwards.
  z-index: map-get($zindex, overlay);
  fill: $white;

  .disabled {
    color: $gray;
    fill: $gray;
  }
}

.more-menu-list li {
  background-color: $blue-black;
  padding: 15px 10px 15px 13px;
}

.more-menu-list li:not(:first-child) {
  border-top: 2px solid rgba(0, 0, 0, 0.4);
}

.more-menu-list svg {
  display: inline-block;
  vertical-align: middle;
}

.more-menu-list p {
  display: inline-block;
  font-weight: 800;
  font-size: 12px;
  letter-spacing: 1.4px;
  margin: 0 0 0 5px;
  padding: 4px 0 2px 10px;
}
