.zoom-ctrl {
  position: absolute;
  z-index: map-get($zindex, button);

  .btn {
    position: relative;
    height: $embedded-mode-control-height;
    width: $embedded-mode-control-width;
    background-color: $embedded-mode-control-background-color;
    border-width: 0;
    border-radius: 0;

    svg {
      width: 30px;
      height: 30px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .btn:active {
    background-color: $embedded-mode-control-active;
  }

  &.zoom-reset-ctrl-embedded {
    @include disable-control;
    top: 0;
    right: 0;
  }

  &.zoom-in-ctrl-embedded {
    @include disable-control;
    right: 0;
    bottom: 80px;
    border-bottom: 1px solid $embedded-mode-page-background-color;
  }

  &.zoom-out-ctrl-embedded {
    @include disable-control;
    bottom: 0;
    right: 0;
  }
}
