$component-connector-tag-width: 22px;
$component-connector-tag-height: 16px;

.component-connector-tag {
  width: $component-connector-tag-width;
  height: $component-connector-tag-height;
  line-height: $component-connector-tag-height;
  margin-right: 4px;
  border-radius: 2px;
  font-size: 10px;
  display: inline-block;
  text-align: center;
}

.component-connector-color-code-bk {
  background: #000000;
  color: #ffffff;
  // Add border to make this element more contrast.
  // We need this because our background in
  // vehicle-components-list has almost the same black color.
  border: 1px white solid;
}

.component-connector-color-code-wh {
  background: #ffffff;
  color: #000000;
}

.component-connector-color-code-bg {
  background: #ffffcc;
  color: #000000;
}

.component-connector-color-code-rd {
  background: #ff3300;
  color: #ffffff;
}

.component-connector-color-code-gn {
  background: #00ff00;
  color: #000000;
}

.component-connector-color-code-ye {
  background: #ffff00;
  color: #000000;
}

.component-connector-color-code-dg {
  background: #009900;
  color: #ffffff;
}

.component-connector-color-code-gy {
  background: #bfbfbf;
  color: #000000;
}

.component-connector-color-code-bn {
  background: #996633;
  color: #000000;
}

.component-connector-color-code-db {
  background: #0066cc;
  color: #000000;
}

// Add border to make this element more contrast.
// We need this because highlighted element in
// vehicle-components-list background has almost the same blue color.
.active .component-connector-color-code-db {
  border: 1px white solid;
}

.component-connector-color-code-vt {
  background: #cc66ff;
  color: #000000;
}

.component-connector-color-code-bu {
  background: #3399ff;
  color: #000000;
}

.component-connector-color-code-pk {
  background: #ffccff;
  color: #000000;
}

.component-connector-color-code-na {
  background: #ffffff;
  color: #000000;
}

.component-connector-color-code-nax {
  background: #ffffff;
  color: #000000;
}

.component-connector-color-code-og {
  background: #ff9900;
  color: #000000;
}

.component-connector-color-code-cu {
  background: #d6a332;
  color: #000000;
}

.component-connector-color-code-bo {
  background: #4c0013;
  color: #ffffff;
}

.component-connector-color-code-pu {
  background: #800080;
  color: #ffffff;
}

.component-connector-color-code-dgy {
  background: #363737;
  color: #ffffff;
}
