$background-color: #272f42;
$background-accent-color: #212739;
$background-light-color: #ffffff;

$font-color-subtext: rgba(255, 255, 255, 0.3);

$selection-color-accent: rgba(224, 79, 77, 0.1);

$brand-color-dark: rgba(1, 137, 233, 0.3);

// ---- TODO Remove everything above when finished with redesign ----

$navbar-height: 60px;
$bold: 500;

/***
* Breakpoints
***/
// Bootstrap's Default
$bootstrap-screen-sm-min: 768px;
$bootstrap-screen-md-min: 992px;
$bootstrap-screen-lg-min: 1200px;

// Custom Sizing
$screen-sm-min: $bootstrap-screen-sm-min;
$screen-md-min: $bootstrap-screen-md-min;
$screen-lg-min: $bootstrap-screen-lg-min;
$screen-xl-min: 1330px;

$screen-xs-max: $screen-sm-min - 1px;
$screen-sm-max: $screen-md-min - 1px;
$screen-md-max: $screen-lg-min - 1px;
$screen-lg-max: $screen-xl-min - 1px;

/***
* Branding
***/
$brand-blue: #0189e9;
$brand-blue-transparent: rgba(0, 137, 233, 0.7);
$brand-light-blue: #81c4f1;
$brand-dark-blue: #0069b2;
$brand-transparent-dark-blue: rgba(0, 105, 178, 0.4);
$brand-green: #1cae5b;
$brand-purple: #5a6382;
$brand-red: #cc422e;
$brand-orange: #e94b35;
$brand-yellow: #ffca57;

$dark-blue: #005fa5;
$report-red: #d74052;
$report-yellow: #ffc000;

$light-blue-black: #2e384f;
$blue-black: #212738;
$blue-black-report: #202737;
$dark-blue-black: #1f232f;

$light-gray: #e7e8ed;
$gray: #7f7f7f;
$dark-gray: #5e6372;
$transparent-gray: rgba(255, 255, 255, 0.2);
$transparent-black: rgba(0, 0, 0, 0.4);
$transparent-white: rgba(255, 255, 255, 0.1);
$transparent-light-gray: rgba(255, 255, 255, 0.5);
$transparent-light-black: rgba(19, 23, 33, 0.5);
$transparent-red: rgba(231, 69, 62, 0.5);

$white: #ffffff;
$black: #000000;
$button-width: 100px;
